// src/components/specific/Customer_documents.jsx

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { approve__customer_detail, reject__customer_detail, update__customer_document } from "../../../../redux/slices/admin"; // Ensure the correct path and define update__customer_document
import { toast } from "react-toastify";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import CustomTextField from "../../../../components/fields/CustomTextField";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { useTheme } from "@mui/material/styles";

const Customer_documents = ({ documents, customer_uuid }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [approvalMessages, setApprovalMessages] = useState("");
  const [actionStatus, setActionStatus] = useState({}); // { [document_uuid]: 'approved' | 'rejected' }
  const [loadingStatus, setLoadingStatus] = useState({}); // { [document_uuid]: boolean }

  // New state for editing
  const [editStatus, setEditStatus] = useState({}); // { [document_uuid]: boolean }
  const [selectedFiles, setSelectedFiles] = useState({}); // { [document_uuid]: File }

  const handleMessageChange = (value) => {
    setApprovalMessages(value);
  };

  const getPartName = () => "Customer Documents";

  const handleApprove = async (document_uuid) => {
    try {
      setLoadingStatus((prev) => ({ ...prev, [document_uuid]: true }));
      const resultAction = await dispatch(
        approve__customer_detail({
          customer_uuid,
          part: "customer_documents",
          id: document_uuid,
          message: approvalMessages,
        })
      );

      if (approve__customer_detail.fulfilled.match(resultAction)) {
        toast.success(`${getPartName()} approved successfully.`);
        setActionStatus((prev) => ({ ...prev, [document_uuid]: "approved" }));
      } else {
        toast.error(resultAction.payload || `Failed to approve ${getPartName()}.`);
      }
    } catch (err) {
      console.error(`Error approving document ${document_uuid}:`, err);
      toast.error(`An unexpected error occurred while approving ${getPartName()}.`);
    } finally {
      setLoadingStatus((prev) => ({ ...prev, [document_uuid]: false }));
    }
  };

  const handleReject = async (document_uuid) => {
    try {
      setLoadingStatus((prev) => ({ ...prev, [document_uuid]: true }));
      const resultAction = await dispatch(
        reject__customer_detail({
          customer_uuid,
          part: "customer_documents",
          id: document_uuid,
          message: approvalMessages,
        })
      );

      if (reject__customer_detail.fulfilled.match(resultAction)) {
        toast.success(`${getPartName()} rejected successfully.`);
        setActionStatus((prev) => ({ ...prev, [document_uuid]: "rejected" }));
      } else {
        toast.error(resultAction.payload || `Failed to reject ${getPartName()}.`);
      }
    } catch (err) {
      console.error(`Error rejecting document ${document_uuid}:`, err);
      toast.error(`An unexpected error occurred while rejecting ${getPartName()}.`);
    } finally {
      setLoadingStatus((prev) => ({ ...prev, [document_uuid]: false }));
    }
  };

  // New handlers for editing
  const handleEdit = (document_uuid) => {
    setEditStatus((prev) => ({ ...prev, [document_uuid]: true }));
  };

  const handleCancelEdit = (document_uuid) => {
    setEditStatus((prev) => ({ ...prev, [document_uuid]: false }));
    setSelectedFiles((prev) => ({ ...prev, [document_uuid]: null }));
  };

  const handleFileChange = (document_uuid, file) => {
    setSelectedFiles((prev) => ({ ...prev, [document_uuid]: file }));
  };

  const handleSaveEdit = async (customer_uuid,document_uuid,document_type) => {
    const file = selectedFiles[document_uuid];
    if (!file) {
      toast.error("Please select a file to upload.");
      return;
    }

    try {
      setLoadingStatus((prev) => ({ ...prev, [document_uuid]: true }));
      const formData = new FormData();
      formData.append("customer_uuid", customer_uuid);
      formData.append("document_uuid", document_uuid);
      formData.append(document_type, file);


      const resultAction = await dispatch(
        update__customer_document({
            formData
        })
      );

      if (update__customer_document.fulfilled.match(resultAction)) {
        toast.success("Document updated successfully.");
        setEditStatus((prev) => ({ ...prev, [document_uuid]: false }));
        // Optionally, update the documents list if necessary
        // e.g., by re-fetching or updating locally
      } else {
        toast.error(resultAction.payload || "Failed to update document.");
      }
    } catch (err) {
      console.error(`Error updating document ${document_uuid}:`, err);
      toast.error("An unexpected error occurred while updating the document.");
    } finally {
      setLoadingStatus((prev) => ({ ...prev, [document_uuid]: false }));
    }
  };

  return (
    <Box mb={4}>
      <Typography variant="h5" gutterBottom color="bronzeShades.bronze2">
        2. Customer Documents
      </Typography>
      <Table sx={{ backgroundColor: theme.palette.silverShades.dark }}>
        <TableBody>
          <TableRow>
            <TableCell>
              <strong>Type</strong>
            </TableCell>
            <TableCell>
              <strong>MIME Type</strong>
            </TableCell>
            <TableCell sx={{ width: '50%' }}>
              <strong>Actions</strong>
            </TableCell>
          </TableRow>
          {documents.length === 0 ? (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No documents available.
              </TableCell>
            </TableRow>
          ) : (
            documents.map((doc) => (
              <TableRow key={doc.document_uuid}>
                <TableCell>{doc.document_type}</TableCell>
                <TableCell>{doc.document_mimetype}</TableCell>
                <TableCell sx={{ width: '50%' }} align="center">
                  {editStatus[doc.document_uuid] ? (
                    <Box display="flex" alignItems="center" gap={1}>
                      <TextField
                        type="file"
                        onChange={(e) =>
                          handleFileChange(doc.document_uuid, e.target.files[0])
                        }
                        inputProps={{ accept: "application/pdf,image/*" }} // Adjust accepted file types as needed
                      />
                      <IconButton
                        color="primary"
                        onClick={() => handleSaveEdit(doc.customer_uuid,doc.document_uuid,doc.document_type)}
                        disabled={loadingStatus[doc.document_uuid]}
                        aria-label="save"
                      >
                        <SaveIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleCancelEdit(doc.document_uuid)}
                        disabled={loadingStatus[doc.document_uuid]}
                        aria-label="cancel"
                      >
                        <CancelPresentationIcon />
                      </IconButton>
                    </Box>
                  ) : actionStatus[doc.document_uuid] === "approved" ? (
                    <Box display="flex" alignItems="center" color="green">
                      <CheckCircleIcon />
                      <Typography ml={1}>Verified</Typography>
                    </Box>
                  ) : actionStatus[doc.document_uuid] === "rejected" ? (
                    <Box display="flex" alignItems="center" color="red">
                      <CancelIcon />
                      <Typography ml={1}>Rejected</Typography>
                    </Box>
                  ) : (
                    <Box display="flex" alignItems="center" gap={1}>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_API_URL}/document_uploads/${encodeURIComponent(
                              doc.document_filename
                            )}`,
                            "_blank"
                          )
                        }
                        sx={{ mr: 1, mb: 1 }}
                      >
                        View
                      </Button>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleApprove(doc.document_uuid)}
                        disabled={loadingStatus[doc.document_uuid] || false}
                        sx={{
                          backgroundColor: theme.palette.bronzeShades.bronze4,
                          color: theme.palette.silverShades.light,
                          "&:hover": {
                            backgroundColor: theme.palette.bronzeShades.bronze1,
                          },
                        }}
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleReject(doc.document_uuid)}
                        disabled={loadingStatus[doc.document_uuid] || false}
                        sx={{
                          backgroundColor: theme.palette.bronzeShades.bronze5,
                          color: theme.palette.silverShades.light,
                          "&:hover": {
                            backgroundColor: theme.palette.bronzeShades.bronze4,
                          },
                        }}
                      >
                        Reject
                      </Button>
                      <IconButton
                        color="primary"
                        onClick={() => handleEdit(doc.document_uuid)}
                        aria-label="edit"
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <Box mt={2} display="flex" alignItems="center" gap={2}>
        <CustomTextField
          label="Message"
          name="outlined"
          fullWidth
          value={approvalMessages}
          onChange={(e) => handleMessageChange(e.target.value)}
          placeholder="Optional message"
        />
      </Box>
    </Box>
  );
};

export default Customer_documents;
