// src/components/buttons/CustomSubmit.jsx

import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomSubmitButton = styled(Button)(({ theme }) => ({
  padding: "2px 16px" ,
  margin: theme.spacing(1),
  outline: "none",
  border: "none",
  borderRadius: "6px",
  fontSize: "16px",
  fontWeight: 600,
  transition: "0.3s all ease",
  cursor: "pointer",
  height: "fit-content",
  background: theme.palette.common.white,
  boxShadow: `
    inset ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(2)} ${theme.palette.silverShades.dark},
    inset -${theme.spacing(1)} -${theme.spacing(1)} ${theme.spacing(2)} ${theme.palette.blackShades.light}
  `,
  "&:hover": {
    background: theme.palette.silverShades.light,
    boxShadow: `
      inset ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(2)} ${theme.palette.silverShades.light},
      inset -${theme.spacing(1)} -${theme.spacing(1)} ${theme.spacing(2)} ${theme.palette.blackShades.medium}
    `,
  },
  "&:active": {
    boxShadow: `
      inset ${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(1)} ${theme.palette.shadows.dark},
      inset -${theme.spacing(0.5)} -${theme.spacing(0.5)} ${theme.spacing(1)} ${theme.palette.shadows.light}
    `,
  },
}));

export default function CustomSubmit({ children, ...props }) {
  return <CustomSubmitButton {...props}>{children}</CustomSubmitButton>;
}
