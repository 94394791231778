// src/components/specific/Customer.jsx

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { approve__customer_detail, reject__customer_detail, update__customer } from "../../../../redux/slices/admin"; // Ensure the correct path
import { toast } from "react-toastify";
import { Box, Typography, Table, TableBody, TableCell, TableRow, Button } from "@mui/material";
import CustomTextField from "../../../../components/fields/CustomTextField";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

import { useTheme } from "@mui/material/styles";

const Customer = ({ initial_customer, customer_uuid }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [customer, setCustomer] = useState(initial_customer);

  const [approvalMessages, setApprovalMessages] = useState("");
  const [actionStatus, setActionStatus] = useState(null); // 'approved' | 'rejected'
  const [loading, setLoading] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editedCustomer, setEditedCustomer] = useState({ ...customer });

  const handleMessageChange = (value) => {
    setApprovalMessages(value);
  };

  const getPartName = () => "Customer Details";

  const handleApprove = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        approve__customer_detail({
          customer_uuid,
          part: "customer",
          id: customer_uuid,
          message: approvalMessages,
        })
      );

      if (approve__customer_detail.fulfilled.match(resultAction)) {
        toast.success(`${getPartName()} approved successfully.`);
        setActionStatus("approved");
      } else {
        toast.error(resultAction.payload || `Failed to approve ${getPartName()}.`);
      }
    } catch (err) {
      console.error(`Error approving customer:`, err);
      toast.error(`An unexpected error occurred while approving ${getPartName()}.`);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        reject__customer_detail({
          customer_uuid,
          part: "customer",
          id: customer_uuid,
          message: approvalMessages,
        })
      );

      if (reject__customer_detail.fulfilled.match(resultAction)) {
        toast.success(`${getPartName()} rejected successfully.`);
        setActionStatus("rejected");
      } else {
        toast.error(resultAction.payload || `Failed to reject ${getPartName()}.`);
      }
    } catch (err) {
      console.error(`Error rejecting customer:`, err);
      toast.error(`An unexpected error occurred while rejecting ${getPartName()}.`);
    } finally {
      setLoading(false);
    }
  };
  const handleSave = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        update__customer({
          customer_uuid,
          data: editedCustomer,
        })
      );

      if (update__customer.fulfilled.match(resultAction)) {
        toast.success("Customer details updated successfully.");
        setIsEditing(false);
        // Optionally, update the local customer state if necessary
        // setCustomerDetails((prev) => ({ ...prev, customer: editedCustomer }));
      } else {
        toast.error(resultAction.payload || "Failed to update customer details.");
      }
    } catch (err) {
      console.error("Error updating customer details:", err);
      toast.error("An unexpected error occurred while updating customer details.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box mb={4}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5" gutterBottom color="bronzeShades.bronze2">
          1. Customer Details
        </Typography>
        <IconButton onClick={() => setIsEditing(true)} aria-label="edit">
          <EditIcon sx={{ color: "silverShades.dark" }} />
        </IconButton>
      </Box>

      <Table sx={{ backgroundColor: theme.palette.silverShades.dark }}>
        <TableBody>
          <TableRow>
            <TableCell>
              <strong>UUID</strong>
            </TableCell>
            <TableCell>{customer_uuid}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {" "}
              <strong>User Type</strong>{" "}
            </TableCell>
            <TableCell>{isEditing ? <CustomTextField value={editedCustomer.user_type} onChange={(e) => setEditedCustomer({ ...editedCustomer, user_type: e.target.value })} /> : customer.user_type}</TableCell>
          </TableRow>
          {customer.user_type === "company" && (
            <>
              <TableRow>
                <TableCell>
                  <strong>Company Name</strong>
                </TableCell>
                <TableCell>{isEditing ? <CustomTextField value={editedCustomer.company_name} onChange={(e) => setEditedCustomer({ ...editedCustomer, company_name: e.target.value })} /> : customer.company_name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Company Type</strong>
                </TableCell>
                <TableCell>{isEditing ? <CustomTextField value={editedCustomer.company_type} onChange={(e) => setEditedCustomer({ ...editedCustomer, company_type: e.target.value })} /> : customer.company_type}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Company VAT Number</strong>
                </TableCell>
                <TableCell>{isEditing ? <CustomTextField value={editedCustomer.company_vat_number} onChange={(e) => setEditedCustomer({ ...editedCustomer, company_vat_number: e.target.value })} /> : customer.company_vat_number}</TableCell>
              </TableRow>
            </>
          )}
          <TableRow>
            <TableCell>
              <strong>Customer Name</strong>
            </TableCell>
            <TableCell>{isEditing ? <CustomTextField value={editedCustomer.customer_name} onChange={(e) => setEditedCustomer({ ...editedCustomer, customer_name: e.target.value })} /> : customer.customer_name}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <strong>Customer Surame</strong>
            </TableCell>
            <TableCell>{isEditing ? <CustomTextField value={editedCustomer.customer_sname} onChange={(e) => setEditedCustomer({ ...editedCustomer, customer_sname: e.target.value })} /> : customer.customer_sname}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <strong>Email</strong>
            </TableCell>
            <TableCell>{isEditing ? <CustomTextField value={editedCustomer.customer_email} onChange={(e) => setEditedCustomer({ ...editedCustomer, customer_email: e.target.value })} /> : customer.customer_email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Cell Number</strong>
            </TableCell>
            <TableCell>{isEditing ? <CustomTextField value={editedCustomer.customer_cell} onChange={(e) => setEditedCustomer({ ...editedCustomer, customer_cell: e.target.value })} /> : customer.customer_cell}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>ID Number</strong>
            </TableCell>
            <TableCell>{isEditing ? <CustomTextField value={editedCustomer.customer_id} onChange={(e) => setEditedCustomer({ ...editedCustomer, customer_id: e.target.value })} /> : customer.customer_id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Account Number</strong>
            </TableCell>
            <TableCell>{isEditing ? <CustomTextField value={editedCustomer.customer_account_number} onChange={(e) => setEditedCustomer({ ...editedCustomer, customer_account_number: e.target.value })} /> : customer.customer_account_number}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Account Holder</strong>
            </TableCell>
            <TableCell>{isEditing ? <CustomTextField value={editedCustomer.customer_account_holder} onChange={(e) => setEditedCustomer({ ...editedCustomer, customer_account_holder: e.target.value })} /> : customer.customer_account_holder}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Bank</strong>
            </TableCell>
            <TableCell>{isEditing ? <CustomTextField value={editedCustomer.customer_bank} onChange={(e) => setEditedCustomer({ ...editedCustomer, customer_bank: e.target.value })} /> : customer.customer_bank}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Branch Code</strong>
            </TableCell>
            <TableCell>{isEditing ? <CustomTextField value={editedCustomer.customer_branch_code} onChange={(e) => setEditedCustomer({ ...editedCustomer, customer_branch_code: e.target.value })} /> : customer.customer_branch_code}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Has Representative</strong>
            </TableCell>
            <TableCell>{isEditing ? <CustomTextField value={customer.customer_representative} onChange={(e) => setEditedCustomer({ ...editedCustomer, customer_representative: e.target.value })} /> : customer.customer_representative ? "true" : "false"}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

        <Box mt={2} display="flex" alignItems="center" gap={2}>
          {isEditing ? (
            <>
              <Button variant="contained" color="primary" onClick={handleSave} startIcon={<SaveIcon />}>
                Save
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setIsEditing(false);
                  setEditedCustomer({ ...customer }); // Reset edited data
                }}
                startIcon={<CancelIcon />}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              <CustomTextField label="Message" name="outlined" fullWidth value={approvalMessages} onChange={(e) => handleMessageChange(e.target.value)} placeholder="Optional message" />
              {/* Existing Approve and Reject buttons */}
              {actionStatus === "approved" ? (
                <Box display="flex" alignItems="center" color="green">
                  <CheckCircleIcon />
                  <Typography ml={1}>Verified</Typography>
                </Box>
              ) : actionStatus === "rejected" ? (
                <Box display="flex" alignItems="center" color="red">
                  <CancelIcon />
                  <Typography ml={1}>Rejected</Typography>
                </Box>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleApprove}
                    disabled={loading}
                    sx={{
                      backgroundColor: theme.palette.bronzeShades.bronze4,
                      color: theme.palette.silverShades.light,
                      "&:hover": {
                        backgroundColor: theme.palette.bronzeShades.bronze1,
                      },
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleReject}
                    disabled={loading}
                    sx={{
                      backgroundColor: theme.palette.bronzeShades.bronze5,
                      color: theme.palette.silverShades.light,
                      "&:hover": {
                        backgroundColor: theme.palette.bronzeShades.bronze4,
                      },
                    }}
                  >
                    Reject
                  </Button>
                </>
              )}
            </>
          )}
        </Box>

    </Box>
  );
};

export default Customer;
