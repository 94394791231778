// src/components/specific/Complete_registration.jsx
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { completeRegistration } from "../../../redux/slices/authSlice"; // Adjust the path as necessary
import { toast } from "react-toastify";
import { Box, Typography, Grid } from "@mui/material";
import CustomTextField from "../../../components/fields/CustomTextField";
import CustomSubmit from "../../../components/fields/CustomSubmit";

const Complete_registration = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const customer_uuid = searchParams.get("customer_uuid");

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    bankingDetails: {
      account_number: "",
      account_holder: "",
      bank: "",
      branch_code: "",
    },
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!customer_uuid) {
      toast.error("Invalid or missing customer UUID.");
      navigate("/"); // Redirect to homepage or an error page
    }
  }, [customer_uuid, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (["account_number", "account_holder", "bank", "branch_code"].includes(name)) {
      setFormData((prevState) => ({
        ...prevState,
        bankingDetails: {
          ...prevState.bankingDetails,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Client-side validation
    if (!formData.username || !formData.password) {
      toast.error("Username and password are required.");
      return;
    }

    if (formData.password.length < 6) {
      toast.error("Password must be at least 6 characters long.");
      return;
    }

    setLoading(true);

    try {
      // Dispatch the completeRegistration action
      const resultAction = await dispatch(
        completeRegistration({
          customer_uuid,
          username: formData.username,
          password: formData.password,
          bankingDetails: formData.bankingDetails,
        })
      );

      if (completeRegistration.fulfilled.match(resultAction)) {
        // Success handled in authSlice's fulfilled case
        navigate("/customer"); // Redirect to login page
      } else {
        // Error handled in authSlice's rejected case
        // Additional error handling can be done here if necessary
      }
    } catch (error) {
      console.error("Registration error:", error);
      toast.error("An unexpected error occurred during registration.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 500,
        margin: "50px auto",
        padding: 3,
        border: "1px solid",
        borderColor: "silverShades.dark",
        borderRadius: 2,
        backgroundColor: "common.bg",
      }}
      className="complete-registration-container"
    >
      <Typography variant="h4" align="center" gutterBottom color="bronzeShades.bronze1">
        Complete Your Registration
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Username */}
          <Grid item xs={12}>
            <CustomTextField label="Username" name="username" value={formData.username} onChange={handleChange} required placeholder="Enter your desired username" />
          </Grid>

          {/* Password */}
          <Grid item xs={12}>
            <CustomTextField label="Password" name="password" type="password" value={formData.password} onChange={handleChange} required minLength={6} placeholder="Enter a secure password" />
          </Grid>

          {/* Banking Details */}
          <Grid item xs={12}>
            <Typography variant="h6" color="bronzeShades.bronze2">
              Banking Details
            </Typography>
          </Grid>

          {/* Account Number */}
          <Grid item xs={12}>
            <CustomTextField label="Account Number" name="account_number" value={formData.bankingDetails.account_number} onChange={handleChange} placeholder="Optional: Enter your account number" />
          </Grid>

          {/* Account Holder */}
          <Grid item xs={12}>
            <CustomTextField label="Account Holder" name="account_holder" value={formData.bankingDetails.account_holder} onChange={handleChange} placeholder="Optional: Enter the account holder's name" />
          </Grid>

          {/* Bank */}
          <Grid item xs={12}>
            <CustomTextField label="Bank" name="bank" value={formData.bankingDetails.bank} onChange={handleChange} placeholder="Optional: Enter your bank name" />
          </Grid>

          {/* Branch Code */}
          <Grid item xs={12}>
            <CustomTextField label="Branch Code" name="branch_code" value={formData.bankingDetails.branch_code} onChange={handleChange} placeholder="Optional: Enter your branch code" />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <CustomSubmit type="submit" disabled={loading} fullWidth>
              {loading ? "Completing Registration..." : "Complete Registration"}
            </CustomSubmit>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Complete_registration;
