// src/components/specific/Verify_registration.js

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { get__customer_details } from "../../../redux/slices/admin"; // Ensure the correct path
import { toast } from "react-toastify";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import Customer from "./components/Customer";
import Customer_documents from "./components/Customer_documents";
import Customer_representative from "./components/Customer_representative";
import Complex from "./components/Complex";
import { useTheme } from "@mui/material/styles";
const Verify_registration = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme=useTheme();
  const searchParams = new URLSearchParams(location.search);
  const customer_uuid = searchParams.get("customer_uuid");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customerDetails, setCustomerDetails] = useState(null);

  useEffect(() => {
    if (!customer_uuid) {
      toast.error("Invalid or missing customer UUID.");
      navigate("/"); // Redirect to admin dashboard or an error page
      return;
    }

    const fetchDetails = async () => {
      try {
        setLoading(true);
        const resultAction = await dispatch(get__customer_details(customer_uuid));
        if (get__customer_details.fulfilled.match(resultAction)) {
          setCustomerDetails(resultAction.payload);
          setError(null);
        } else {
          setError(resultAction.payload || "Failed to fetch customer details.");
          setCustomerDetails(null);
        }
      } catch (err) {
        console.error("Error fetching customer details:", err);
        setError("An unexpected error occurred while fetching customer details.");
        setCustomerDetails(null);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [customer_uuid, dispatch, navigate]);

  const handleComplete = () => {
    navigate("/");
  };

  if (loading && !customerDetails) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress  size={24} color={theme.palette.silverShades.light}/>
      </Box>
    );
  }

  if (error && !customerDetails) {
    return (
      <Box textAlign="center" p={4}>
        <Typography variant="h5" color="error" gutterBottom>
          {error}
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/")}
          sx={{
            backgroundColor: (theme) => theme.palette.bronzeShades.bronze2,
            color: (theme) => theme.palette.silverShades.light,
            "&:hover": {
              backgroundColor: (theme) => theme.palette.bronzeShades.bronze1,
            },
          }}
        >
          Go to Admin Dashboard
        </Button>
      </Box>
    );
  }

  if (!customerDetails) {
    return null; // Or a placeholder
  }

  const { customer, documents, representative, complexes } = customerDetails;

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1000px",
        margin: "20px auto",
        padding: "20px",
        background: "rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(10px)",
        boxShadow: "0 8px 32px 0 rgba(0, 0, 0, 0.1)",
        borderRadius: "16px",
        border: "1px solid rgba(255, 255, 255, 0.3)",
        color: (theme) => theme.palette.silverShades.light,
      }}
    >
      <Typography variant="h4" align="center" gutterBottom color="bronzeShades.bronze1">
        Verify Customer Registration
      </Typography>

      {/* Individual Components */}
      <Customer initial_customer={customer} customer_uuid={customer_uuid} />
      <Customer_documents documents={documents} customer_uuid={customer_uuid} />
      {representative && <Customer_representative representative={representative} customer_uuid={customer_uuid} />}
      <Complex complexes={complexes} customer_uuid={customer_uuid} />

      {/* Complete Button */}
      <Box mb={4}>
        <Button
          variant="contained"
          color="success"
          onClick={handleComplete}
          fullWidth
          sx={{
            backgroundColor: (theme) => theme.palette.blackShades.medium,
            color: (theme) => theme.palette.silverShades.light,
            "&:hover": {
              backgroundColor: (theme) => theme.palette.blackShades.black,
            },
          }}
        >
          Complete
        </Button>
      </Box>
    </Box>
  );
};

export default Verify_registration;
