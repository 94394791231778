// src/pages/WebsitePage.jsx
import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, Card, CardContent, Button } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";

import { Link as RouterLink, useLocation } from "react-router-dom";
import landingPageImage from "../../../assets/bulbs/bulbs0.jpg";
import aboutUsImage from "../../../assets/bulbs/bulbs5.jpg";
import contactUsImage from "../../../assets/bulbs/bulbs6.jpg";

import { motion, AnimatePresence } from "framer-motion";
import Carousel from "./Carousel";
import GetInTouch from "./GetInTouch";

import PersonAddIcon from "@mui/icons-material/PersonAdd"; // Icon for Register button

import PageArrows from "../../../components/custom/PageArrows";

const NeumorphicCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.blackShades.black,
  color: theme.palette.silverShades.light,
  boxShadow: `2px 2px 2px ${theme.palette.blackShades.medium}, -2px -2px 2px ${theme.palette.blackShades.light}`,
  borderRadius: "15px",
  maxWidth: "15%",
  padding: theme.spacing(0.1),
  flexGrow: 1, // Allows the card to grow and fill available space
  display: "flex",
  flexDirection: "column",
  minHeight: 0, // Important for flex items
}));
const StyledContainer = styled(Box)(({ theme, section }) => ({
  height: "100vh",
  width: "100%",
  position: "fixed",
  top: 0,
  left: 0,
  backgroundImage: section === 2 ? `url(${aboutUsImage})` : section === 3 ? `url(${contactUsImage})` : `url(${landingPageImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed", // Keeps the background fixed
  overflow: "hidden", // Disables scroll
  
}));
const GlassmorphismContainer = styled(Box)(({ theme }) => ({
  background: "rgba(0, 0, 0, 0.5)",
  borderRadius: "15px",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(5px)",
  WebkitBackdropFilter: "blur(5px)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
}));
const RegisterButton = () => {
  const location = useLocation();
  const theme = useTheme();

  return (
    <Button
      component={RouterLink}
      to="/register"
      sx={{
        position: "fixed", // Fixed position relative to the viewport
        top: "50%", // Vertically centered
        right: "20px", // Positioned 20px from the right
        transform: "translateY(-50%)", // Adjusts for perfect vertical centering
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1, 3),
        borderRadius: "12px",
        backgroundColor: location.pathname === "/register" ? theme.palette.bronzeShades.bronze2 : theme.palette.bronzeShades.bronze1,
        color: theme.palette.silverShades.light,
        fontWeight: "bold",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
        textTransform: "none",
        transition: "background-color 0.3s, color 0.3s, box-shadow 0.3s",
        "&:hover": {
          backgroundColor: theme.palette.bronzeShades.bronze3,
          boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.3)",
          color: theme.palette.blackShades.dark,
        },
        zIndex: 1300, // Ensures the button stays above other elements
      }}
    >
      <PersonAddIcon sx={{ marginRight: 1, fontSize: "1.2rem" }} />
      Register
    </Button>
  );
};

function WebsitePage() {
  const theme = useTheme();
  const [section, setSection] = useState(0);
  const maxSection = 3;

  const carouselVariants = {
    enter: { opacity: 0, x: -300 },
    center: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 300 },
  };
  const cardsVariants = {
    enter: { opacity: 0, scale: 0.5, rotate: -15 },
    center: { opacity: 1, scale: 1, rotate: 0 },
    exit: { opacity: 0, scale: 0.5, rotate: 15 },
  };
  const aboutUsVariants = {
    enter: { opacity: 0, y: 300 },
    center: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -300 },
  };
  const contactVariants = {
    enter: { opacity: 0, rotateY: -90 },
    center: { opacity: 1, rotateY: 0 },
    exit: { opacity: 0, rotateY: 90 },
  };

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
        timer = null;
      }, delay);
    };
  };

  const handleScroll = useCallback(
    debounce((event) => {
      setSection((prevSection) => {
        if (event.deltaY > 0) {
          return Math.min(prevSection + 1, maxSection);
        } else if (event.deltaY < 0) {
          return Math.max(prevSection - 1, 0);
        } else {
          return prevSection;
        }
      });
    }, 100),
    []
  );

  useEffect(() => {
    window.addEventListener("wheel", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, [handleScroll]);

  return (
    <StyledContainer section={section}>
      <RegisterButton />

      <AnimatePresence>
        {section == 0 && (
          <motion.div
            key="carousel"
            variants={carouselVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: 0.8, ease: "easeInOut" }}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "50%",
              height: "100%",
            }}
          >
            <Carousel />
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {section === 1 && (
          <motion.div
            key="content"
            variants={cardsVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: 0.8, ease: "anticipate" }}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%", // Occupy full width or adjust as needed
              height: "100%",
            }}
          >
            {/* Overlay Content */}
            <Box
              sx={{
                position: "absolute",
                top: "25%",
                left: "8%", // Position content 8% from the left
                transform: "translateY(-50%)", // Center vertically
                color: "#fff",
                textAlign: "left",
                maxWidth: "45%", // Optional: Limit content width
              }}
            >
              <Typography variant="h2" gutterBottom>
                Expert Utility Management for your Sub-Metering needs
              </Typography>
              <Typography variant="subtitle1">We provide precise monitoring, billing, and reporting services, ensuring efficient energy usage and cost control for property owners and managers. With our advanced technology and seamless integration, we deliver accurate, reliable data to help you optimize energy consumption and improve tenant satisfaction.</Typography>
            </Box>

            <Box
              sx={{
                position: "absolute",
                top: "65%",
                left: "8%",
                transform: "translateY(-50%)",
                display: "flex",
                gap: 3,
                mt: 0,
              }}
            >
              {/* Card 1 */}
              <NeumorphicCard>
                <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
                  <SavingsOutlinedIcon sx={{ fontSize: "2rem", color: theme.palette.bronzeShades.bronze1 }} />
                  <Typography
                    variant="h4"
                    sx={{
                      color: theme.palette.silverShades.light,
                      mb: "1rem",
                      mt: "0.1rem",
                    }}
                  >
                    Cost Effective
                  </Typography>
                  <Typography variant="body1" sx={{ color: theme.palette.silverShades.dark }}>
                    Our company provides high-quality, affordable services with competitive and transparent pricing. By optimizing processes and using innovative solutions, we ensure excellent value without compromising quality, allowing you to focus on what matters most.
                  </Typography>
                </CardContent>
              </NeumorphicCard>

              {/* Card 2 */}
              <NeumorphicCard>
                <CardContent>
                  <GroupsOutlinedIcon sx={{ fontSize: "2rem", color: theme.palette.bronzeShades.bronze1 }} />
                  <Typography variant="h4" sx={{ color: theme.palette.silverShades.light, mb: "1rem", mt: "0.1rem" }}>
                    Embracing Innovations
                  </Typography>
                  <Typography variant="body1" sx={{ color: theme.palette.silverShades.dark }}>
                    With our modern and flexible approach to continious improvement, our properity software offers the adaptability that you need to ensure optimum utility recovery
                  </Typography>
                </CardContent>
              </NeumorphicCard>

              {/* Card 3 */}
              <NeumorphicCard>
                <CardContent>
                  <TipsAndUpdatesOutlinedIcon sx={{ fontSize: "2rem", color: theme.palette.bronzeShades.bronze1 }} />
                  <Typography variant="h4" sx={{ color: theme.palette.silverShades.light, mb: "1rem", mt: "0.1rem" }}>
                    Tailored Solutions
                  </Typography>
                  <Typography variant="body1" sx={{ color: theme.palette.silverShades.dark }}>
                    We provide fully customized reports tailored to your specific needs at no extra cost, with a quick turnaround to ensure you get the insights you need on time. Our flexible approach allows us to create custom packages, including pricing, designed to suit your requirements perfectly.
                  </Typography>
                </CardContent>
              </NeumorphicCard>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {section === 2 && (
          <motion.div
            key="about-us"
            variants={aboutUsVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: 0.8, ease: "backInOut" }}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
            }}
          >
            {/* About Us content */}
            <GlassmorphismContainer
              sx={{
                position: "absolute",
                top: "25%",
                left: "8%",
                color: "#fff",
                textAlign: "left",
                maxWidth: "45%",
                padding: theme.spacing(4),
              }}
            >
              <Typography variant="h2" gutterBottom sx={{ color: theme.palette.bronzeShades.bronze4 }}>
                About Us
              </Typography>
              <Typography variant="subtitle1">With over 25 years of experience in the metering industry, we pride ourselves on delivering innovative solutions tailored to meet the diverse needs of our customers. Our strong development team is dedicated to advancing technology, ensuring that we stay at the forefront of the industry. By fostering robust relationships with leading suppliers, we offer a wide variety of high-quality products that enhance efficiency and accuracy. Our commitment to constant innovation drives us to continuously improve our offerings, empowering our clients to optimize their resource management effectively. Join us as we lead the way in metering solutions!</Typography>
            </GlassmorphismContainer>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {section === 3 && (
          <motion.div
            key="contact"
            variants={contactVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: 0.8, ease: "easeInOut" }}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
            }}
          >
            <GetInTouch />
          </motion.div>
        )}
      </AnimatePresence>

      <PageArrows section={section} setSection={setSection} maxSection={maxSection} />
    </StyledContainer>
  );
}

export default WebsitePage;
