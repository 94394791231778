// src/components/specific/Customer_representative.jsx

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  approve__customer_detail,
  reject__customer_detail,
  update__customer_representative, // Ensure this action is defined in your Redux slice
} from "../../../../redux/slices/admin"; // Ensure the correct path
import { toast } from "react-toastify";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  IconButton,
} from "@mui/material";
import CustomTextField from "../../../../components/fields/CustomTextField";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { useTheme } from "@mui/material/styles";

const Customer_representative = ({ representative, customer_uuid }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [approvalMessages, setApprovalMessages] = useState("");
  const [actionStatus, setActionStatus] = useState(null); // 'approved' | 'rejected'
  const [loading, setLoading] = useState(false);

  // New state for editing
  const [isEditing, setIsEditing] = useState(false);
  const [editedRepresentative, setEditedRepresentative] = useState({
    ...representative,
  });

  const handleMessageChange = (value) => {
    setApprovalMessages(value);
  };

  const getPartName = () => "Customer Representative";

  // Existing approve handler
  const handleApprove = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        approve__customer_detail({
          customer_uuid,
          part: "customer_representative",
          id: representative.representative_uuid,
          message: approvalMessages,
        })
      );

      if (approve__customer_detail.fulfilled.match(resultAction)) {
        toast.success(`${getPartName()} approved successfully.`);
        setActionStatus("approved");
      } else {
        toast.error(resultAction.payload || `Failed to approve ${getPartName()}.`);
      }
    } catch (err) {
      console.error(`Error approving representative:`, err);
      toast.error(`An unexpected error occurred while approving ${getPartName()}.`);
    } finally {
      setLoading(false);
    }
  };

  // Existing reject handler
  const handleReject = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        reject__customer_detail({
          customer_uuid,
          part: "customer_representative",
          id: representative.representative_uuid,
          message: approvalMessages,
        })
      );

      if (reject__customer_detail.fulfilled.match(resultAction)) {
        toast.success(`${getPartName()} rejected successfully.`);
        setActionStatus("rejected");
      } else {
        toast.error(resultAction.payload || `Failed to reject ${getPartName()}.`);
      }
    } catch (err) {
      console.error(`Error rejecting representative:`, err);
      toast.error(`An unexpected error occurred while rejecting ${getPartName()}.`);
    } finally {
      setLoading(false);
    }
  };

  // New handler to initiate edit mode
  const handleEdit = () => {
    setIsEditing(true);
  };

  // New handler to cancel edit mode
  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedRepresentative({ ...representative }); // Reset edited data
  };

  // New handler to save edited representative details
  const handleSaveEdit = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        update__customer_representative({
          representative_uuid: representative.representative_uuid,
          data: editedRepresentative,
        })
      );

      if (update__customer_representative.fulfilled.match(resultAction)) {
        toast.success("Customer Representative details updated successfully.");
        setIsEditing(false);
        // Optionally, update the local representative data if necessary
        // For example, you might receive the updated data from the backend
        // and set it here. This depends on your Redux setup.
      } else {
        toast.error(resultAction.payload || "Failed to update Customer Representative details.");
      }
    } catch (err) {
      console.error("Error updating Customer Representative details:", err);
      toast.error("An unexpected error occurred while updating Customer Representative details.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box mb={4}>
      {/* Modified Heading with Edit Button */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5" gutterBottom color="bronzeShades.bronze2">
          3. Customer Representative
        </Typography>
        <IconButton onClick={handleEdit} aria-label="edit">
          <EditIcon sx={{ color: "silverShades.dark" }}/>
        </IconButton>
      </Box>

      {/* Representative Details Table with Conditional Rendering */}
      <Table sx={{ backgroundColor: theme.palette.silverShades.dark }}>
        <TableBody>
          <TableRow>
            <TableCell>
              <strong>Representative UUID</strong>
            </TableCell>
            <TableCell>{representative.representative_uuid}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell>
              {isEditing ? (
                <CustomTextField
                  value={editedRepresentative.representative_name}
                  onChange={(e) =>
                    setEditedRepresentative({
                      ...editedRepresentative,
                      representative_name: e.target.value,
                    })
                  }
                />
              ) : (
                `${representative.representative_name} ${representative.representative_sname}`
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Email</strong>
            </TableCell>
            <TableCell>
              {isEditing ? (
                <CustomTextField
                  value={editedRepresentative.representative_email}
                  onChange={(e) =>
                    setEditedRepresentative({
                      ...editedRepresentative,
                      representative_email: e.target.value,
                    })
                  }
                />
              ) : (
                representative.representative_email
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Cell Number</strong>
            </TableCell>
            <TableCell>
              {isEditing ? (
                <CustomTextField
                  value={editedRepresentative.representative_cell}
                  onChange={(e) =>
                    setEditedRepresentative({
                      ...editedRepresentative,
                      representative_cell: e.target.value,
                    })
                  }
                />
              ) : (
                representative.representative_cell || "N/A"
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>ID Number</strong>
            </TableCell>
            <TableCell>
              {isEditing ? (
                <CustomTextField
                  value={editedRepresentative.representative_id}
                  onChange={(e) =>
                    setEditedRepresentative({
                      ...editedRepresentative,
                      representative_id: e.target.value,
                    })
                  }
                />
              ) : (
                representative.representative_id
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Account Number</strong>
            </TableCell>
            <TableCell>
              {isEditing ? (
                <CustomTextField
                  value={editedRepresentative.representative_account_number}
                  onChange={(e) =>
                    setEditedRepresentative({
                      ...editedRepresentative,
                      representative_account_number: e.target.value,
                    })
                  }
                />
              ) : (
                representative.representative_account_number || "N/A"
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Account Holder</strong>
            </TableCell>
            <TableCell>
              {isEditing ? (
                <CustomTextField
                  value={editedRepresentative.representative_account_holder}
                  onChange={(e) =>
                    setEditedRepresentative({
                      ...editedRepresentative,
                      representative_account_holder: e.target.value,
                    })
                  }
                />
              ) : (
                representative.representative_account_holder || "N/A"
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Bank</strong>
            </TableCell>
            <TableCell>
              {isEditing ? (
                <CustomTextField
                  value={editedRepresentative.representative_bank}
                  onChange={(e) =>
                    setEditedRepresentative({
                      ...editedRepresentative,
                      representative_bank: e.target.value,
                    })
                  }
                />
              ) : (
                representative.representative_bank || "N/A"
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Branch Code</strong>
            </TableCell>
            <TableCell>
              {isEditing ? (
                <CustomTextField
                  value={editedRepresentative.representative_branch_code}
                  onChange={(e) =>
                    setEditedRepresentative({
                      ...editedRepresentative,
                      representative_branch_code: e.target.value,
                    })
                  }
                />
              ) : (
                representative.representative_branch_code || "N/A"
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* Conditional Rendering of Buttons Based on Edit Mode */}
      <Box mt={2} display="flex" alignItems="center" gap={2}>
        {isEditing ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveEdit}
              startIcon={<SaveIcon />}
              disabled={loading}
              sx={{
                backgroundColor: theme.palette.bronzeShades.bronze4,
                color: theme.palette.silverShades.light,
                "&:hover": {
                  backgroundColor: theme.palette.bronzeShades.bronze1,
                },
              }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancelEdit}
              startIcon={<CancelPresentationIcon />}
              disabled={loading}
              sx={{
                borderColor: theme.palette.bronzeShades.bronze5,
                color: theme.palette.silverShades.light,
                "&:hover": {
                  borderColor: theme.palette.bronzeShades.bronze4,
                  backgroundColor: theme.palette.bronzeShades.bronze1,
                },
              }}
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            <CustomTextField
              label="Message"
              name="outlined"
              fullWidth
              value={approvalMessages}
              onChange={(e) => handleMessageChange(e.target.value)}
              placeholder="Optional message"
            />
            {actionStatus === "approved" ? (
              <Box display="flex" alignItems="center" color="green">
                <CheckCircleIcon />
                <Typography ml={1}>Verified</Typography>
              </Box>
            ) : actionStatus === "rejected" ? (
              <Box display="flex" alignItems="center" color="red">
                <CancelIcon />
                <Typography ml={1}>Rejected</Typography>
              </Box>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleApprove}
                  disabled={loading}
                  sx={{
                    backgroundColor: theme.palette.bronzeShades.bronze4,
                    color: theme.palette.silverShades.light,
                    "&:hover": {
                      backgroundColor: theme.palette.bronzeShades.bronze1,
                    },
                  }}
                >
                  Approve
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleReject}
                  disabled={loading}
                  sx={{
                    backgroundColor: theme.palette.bronzeShades.bronze5,
                    color: theme.palette.silverShades.light,
                    "&:hover": {
                      backgroundColor: theme.palette.bronzeShades.bronze4,
                    },
                  }}
                >
                  Reject
                </Button>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Customer_representative;
