// src/components/layout/WebsiteLayout.jsx

import React, { useRef } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import TopNavBar from "../pages/website/navigation/TopNavBar";
import { useTheme } from "@mui/material/styles";
import Footer from "../components/common/Footer";

function WebsiteLayout() {
  const theme = useTheme();
  const contentRef = useRef(null);
  const navbarHeight = 64;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <TopNavBar />
      <Box
        component="main"
        className="main-content" // Add a class name for styling
        ref={contentRef}
        sx={{
          flexGrow: 1,
          p: 0,
          height: `calc(100vh - ${navbarHeight}px)`,
          background: theme.palette.blackShades.black,
          overflowY: "auto",
        }}
      >
        <Outlet context={{ contentRef }} />
      </Box>
      <Footer />
    </Box>
  );
}

export default WebsiteLayout;
