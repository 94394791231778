import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import NavButton from './NavButton';
import { useDispatch } from 'react-redux';
import { logout } from '../../../redux/slices/authSlice';

const SideNavContainer = styled(Box)(({ theme }) => ({
  width: '150px',
  height: '100vh',
  backgroundColor: theme.palette.blackShades.medium,
  boxShadow: `2px 0 5px rgba(0,0,0,0.1)`,
  position: 'fixed',
  top: 0,
  left: 0,
  padding: theme.spacing(4, 2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

function SideNavBar() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  return (
    <SideNavContainer>
      <Typography variant="h5" sx={{ color: theme.palette.bronzeShades.bronze1, marginBottom: theme.spacing(4) }}>
        Customer Panel
      </Typography>
      <NavButton
        to="/customer"
        isActive={location.pathname === '/customer'}
      >
        Home
      </NavButton>
      <NavButton
        onClick={handleLogout}
      >
        Logout
      </NavButton>
    </SideNavContainer>
  );
}

export default SideNavBar;
