// src/redux/slices/authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const registerUser = createAsyncThunk("auth/registerUser", async (formData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/website/register-individual`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    // Handle errors appropriately
    return rejectWithValue(error.response?.data?.message || "An error occurred during registration.");
  }
});
export const registerCompany = createAsyncThunk("auth/registerCompany", async (formData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/website/register-company`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    // Handle errors appropriately
    return rejectWithValue(error.response?.data || "An error occurred during registration.");
  }
});
export const registerAdmin = createAsyncThunk(
  "auth/registerAdmin",
  async (adminData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/website/register-admin`, adminData);
      return response.data;
    } catch (error) {
      // Customize the error message based on your API response
      return rejectWithValue(
        error.response?.data?.error || "Admin registration failed."
      );
    }
  }
);
export const completeRegistration = createAsyncThunk("auth/completeRegistration", async ({ customer_uuid, username, password, bankingDetails }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/website/complete-registration`, {
      customer_uuid,
      username,
      password,
      bankingDetails,
    });
    return response.data;
  } catch (error) {
    // Handle errors appropriately
    return rejectWithValue(error.response?.data?.message || "An error occurred during registration completion.");
  }
});

export const loginUser = createAsyncThunk("auth/loginUser", async (credentials, { rejectWithValue }) => {
  try {
    // Replace '/api/login' with your actual login endpoint
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/website/login`, credentials);
    console.log({ response: response.data });
    return response.data; // Assume backend returns user data including role
  } catch (error) {
    return rejectWithValue(error.response?.data || "An error occurred during login.");
  }
});
export const forgotPassword = createAsyncThunk('auth/forgotPassword', async (contactInfo, { rejectWithValue }) => {
  try {
    const response = await axios.post('/api/auth/forgot-password', contactInfo);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const initialState = {
  isAuthenticated: false,
  role: null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      state.isAuthenticated = false;
      state.role = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.role = action.payload.role; // Adjust based on your API response
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Registration failed.";
        if (Array.isArray(state.error)) {
          const message = (
            <div>
              {state.error.map((msg, index) => (
                <div key={index}>{msg}</div>
              ))}
            </div>
          );
          toast.error(message);
        } else {
          toast.error(state.error);
        }
      })

      .addCase(registerCompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.role = action.payload.role; // Adjust based on your API response
      })
      .addCase(registerCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Registration failed.";
        if (Array.isArray(state.error)) {
          // Convert array to JSX with line breaks
          const message = (
            <div>
              {state.error.map((msg, index) => (
                <div key={index}>{msg}</div>
              ))}
            </div>
          );
          toast.error(message);
        } else {
          toast.error(state.error);
        }
      })

      .addCase(completeRegistration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(completeRegistration.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.role = action.payload.role; // Adjust based on your API response
        toast.success("Registration completed successfully! You can now log in.");
      })
      .addCase(completeRegistration.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Registration completion failed.";
        if (Array.isArray(state.error)) {
          const message = (
            <div>
              {state.error.map((msg, index) => (
                <div key={index}>{msg}</div>
              ))}
            </div>
          );
          toast.error(message);
        } else {
          toast.error(state.error);
        }
      })

      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.role = action.payload.role; // Adjust based on your API response
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "Login failed.";
      });

      builder
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        // You can add any additional state updates if needed
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

      builder
      .addCase(registerAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(registerAdmin.rejected, (state, action) => {
        console.log(action.payload)
        state.loading = false;
        state.error = action.payload;
        toast.error(state.error);
      });
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
