// src/components/website/TopNavBar.jsx
import React, { useState, useRef, useEffect } from "react";
import { AppBar, Toolbar, Typography, Button,  Box } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Divider } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { openLoginModal } from "../../../redux/slices/uiSlice";
import { logout } from "../../../redux/slices/authSlice";

import NavButton from "./NavButton";
import DropdownMenu from "./DropdownMenu";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew"; // Import the desired icon
import HomeIcon from "@mui/icons-material/Home";

import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import GpsFixedOutlinedIcon from "@mui/icons-material/GpsFixedOutlined";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
// import ElectricMeterOutlinedIcon from "@mui/icons-material/ElectricMeterOutlined";

import ConnectWithoutContactOutlinedIcon from "@mui/icons-material/ConnectWithoutContactOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";

const GradientAppBar = styled(AppBar)(({ theme }) => ({
  background: `linear-gradient(to right, ${theme.palette.blackShades.black}, ${theme.palette.blackShades.dark})`,
  position: "sticky", // Change from 'fixed' to 'sticky'
  top: 0,
  zIndex: theme.zIndex.appBar, // Ensure it stays above content
}));

function TopNavBar() {
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const handleLoginOpen = () => dispatch(openLoginModal());
  const handleLogout = () => dispatch(logout());

  const [activeDropdown, setActiveDropdown] = useState(null);

  const servicesRef = useRef(null);
  const productsRef = useRef(null);
  const servicesMenuItems = [
    { label: "Utility Sub-Metering", link: "/services/utility-sub-metering", icon: <BoltOutlinedIcon /> },
    { label: "Vending Solution", link: "/services/vending-solution", icon: <GpsFixedOutlinedIcon /> },
    { label: "Bulk Metering", link: "/services/bulk-metering", icon: <ProductionQuantityLimitsOutlinedIcon /> },
    { label: "Recharge", link: "/services/recharge", icon: <PowerSettingsNewIcon /> },
  ];

  const productsMenuItems = [
    { label: "Suppliers", link: "/products/suppliers", icon: <Inventory2OutlinedIcon /> },
    { label: "Connectivity", link: "/products/connectivity", icon: <ConnectWithoutContactOutlinedIcon /> },
  ];

  const handleMouseEnter = (dropdownName) => {
    setActiveDropdown(dropdownName);
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (servicesRef.current && !servicesRef.current.contains(event.target) && productsRef.current && !productsRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Determine active states
  const isServicesActive = location.pathname.startsWith("/services");
  const isProductsActive = location.pathname.startsWith("/products");

  const StyledDivider = styled(Divider)(({ theme }) => ({
    marginLeft: theme.spacing(2), // Horizontal margin
    marginRight: theme.spacing(2),
    borderColor: theme.palette.blackShades.medium,
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
  }));

  return (
    <>
      <GradientAppBar position="fixed" sx={{ left: "0px", justifyContent: "center", maxWidth: "100%" }}>
        <Toolbar sx={{ minHeight: "64px" }}>
          <Typography
            variant="h4"
            sx={(theme) => ({
              flexGrow: 1,
              color: theme.palette.silverShades.light,
            })}
          >
            Meter Minds
          </Typography>

          {/* <NavButton
            component={RouterLink}
            to="/palette"
            className={location.pathname === "/palette" ? "active" : ""}
            sx={{
              marginRight: "50px",
            }}
          >
            theme
          </NavButton> */}

          <NavButton component={RouterLink} to="/" className={location.pathname === "/" ? "active" : ""}>
            <HomeIcon />
          </NavButton>

          <StyledDivider orientation="vertical" flexItem />

          {/* Services Dropdown */}
          <Box
            ref={servicesRef}
            sx={{
              position: "relative",
            }}
            onMouseEnter={() => handleMouseEnter("services")}
            onMouseLeave={handleMouseLeave}
          >
            <Button
              aria-haspopup="true"
              aria-expanded={activeDropdown === "services" ? "true" : undefined}
              endIcon={<ArrowDropDownIcon />}
              sx={{
                color: theme.palette.silverShades.light,
                "&:hover": {
                  backgroundColor: theme.palette.blackShades.dark,
                  color: theme.palette.bronzeShades.bronze1,
                },
                ...(isServicesActive && {
                  backgroundColor: theme.palette.blackShades.black,
                  color: theme.palette.bronzeShades.bronze1,
                }),
                textTransform: "none",
              }}
            >
              Services
            </Button>
            {activeDropdown === "services" && <DropdownMenu items={servicesMenuItems} onClose={() => setActiveDropdown(null)} />}
          </Box>

          {/* Products Dropdown */}
          <Box
            ref={productsRef}
            sx={{
              position: "relative",
              marginLeft: theme.spacing(2),
            }}
            onMouseEnter={() => handleMouseEnter("products")}
            onMouseLeave={handleMouseLeave}
          >
            <Button
              aria-haspopup="true"
              aria-expanded={activeDropdown === "products" ? "true" : undefined}
              endIcon={<ArrowDropDownIcon />}
              sx={{
                color: theme.palette.silverShades.light,
                "&:hover": {
                  backgroundColor: theme.palette.blackShades.dark,
                  color: theme.palette.bronzeShades.bronze1,
                },
                ...(isProductsActive && {
                  backgroundColor: theme.palette.blackShades.black,
                  color: theme.palette.bronzeShades.bronze1,
                }),
                textTransform: "none",
              }}
            >
              Products
            </Button>
            {activeDropdown === "products" && <DropdownMenu items={productsMenuItems} onClose={() => setActiveDropdown(null)} />}
          </Box>

          <StyledDivider orientation="vertical" flexItem />

          <Button
            component={RouterLink}
            to="/services/recharge"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
              textTransform: "none",
              marginLeft: theme.spacing(1),
              backgroundColor: location.pathname === "/recharge" ? theme.palette.blackShades.dark : theme.palette.blackShades.black,
              color: location.pathname === "/recharge" ? theme.palette.silverShades.light : theme.palette.silverShades.light,
              fontWeight: "normal",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
              transition: "background-color 0.3s, color 0.3s, box-shadow 0.3s",
              "&:hover": {
                backgroundColor: theme.palette.bronzeShades.bronze1,
                boxShadow: "0 6px 16px rgba(0, 0, 0, 0.3)",
                color: theme.palette.blackShades.dark,
              },
            })}
          >
            <PowerSettingsNewIcon sx={{ marginRight: 1 }} />
            Recharge
          </Button>

          <StyledDivider orientation="vertical" flexItem />

          {!auth.isAuthenticated ? (
            <Button
              color="inherit"
              onClick={handleLoginOpen}
              sx={(theme) => ({
                borderRadius: "12px",
                background: theme.palette.silverShades.dark,
                color: theme.palette.blackShades.black,
                marginLeft: "25px",
                py: "1px",
                transition: "background-color 0.3s, color 0.3s",
                "&:hover": {
                  background: theme.palette.blackShades.black,
                  color: theme.palette.silverShades.light,
                },
              })}
            >
              Login
            </Button>
          ) : (
            <Button
              color="inherit"
              onClick={handleLogout}
              sx={(theme) => ({
                borderRadius: "12px",
                background: theme.palette.silverShades.light,
                color: theme.palette.blackShades.black,
                marginLeft: "25px",
              })}
            >
              Logout
            </Button>
          )}
        </Toolbar>
      </GradientAppBar>
    </>
  );
}

export default TopNavBar;
