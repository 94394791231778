//src/pages/login/Login.jsx
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { loginUser } from "../../redux/slices/authSlice";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Box, CircularProgress, Link } from "@mui/material";
import { styled } from "@mui/material/styles";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ForgotPassword from "./components/ForgotPassword";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: theme.palette.bronzeShades.bronze1,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: theme.palette.bronzeShades.bronze4,
  },
  "& .MuiInputBase-input": {
    color: theme.palette.silverShades.light,
  },
  "& .MuiFilledInput-underline:before": {
    borderBottomColor: theme.palette.silverShades.light,
  },
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: theme.palette.silverShades.light,
  },
}));

function LoginForm({ open, handleClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const [credentials, setCredentials] = useState({ username: "", password: "" });
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    dispatch(loginUser(credentials))
      .unwrap()
      .then((data) => {
        // Login succeeded
        handleClose();
        if (data.role === "admin") {
          navigate("/admin");
        } else if (data.role === "customer") {
          navigate("/customer");
        } else {
          navigate("/");
        }
      })
      .catch((err) => {
        // Display error toast
        toast.error(err.message || "Invalid credentials");
      });
  };

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
  };

  const handleBackToLogin = () => {
    setIsForgotPassword(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            background: "rgba(255, 255, 255, 0.1)", // Semi-transparent background
            backdropFilter: "blur(10px)", // Blur effect
            boxShadow: `0 8px 32px 0 ${theme.palette.blackShades.dark}`,
            borderRadius: "16px",
            border: `1px solid ${theme.palette.blackShades.light}`,
            color: theme.palette.silverShades.light,
          },
        }}
      >
        {!isForgotPassword ? (
          <>
            <DialogTitle
              sx={{
                textAlign: "center",
                color: theme.palette.bronzeShades.bronze4,
                fontWeight: "bold",
              }}
            >
              Login
            </DialogTitle>
            <DialogContent>
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  mt: 1,
                }}
              >
                <StyledTextField
                  autoFocus
                  margin="dense"
                  name="username"
                  label="Username"
                  type="text"
                  fullWidth
                  variant="filled"
                  value={credentials.username}
                  onChange={handleChange}
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    borderRadius: "8px",
                  }}
                />
                <StyledTextField
                  margin="dense"
                  name="password"
                  label="Password"
                  type="password"
                  fullWidth
                  variant="filled"
                  value={credentials.password}
                  onChange={handleChange}
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    borderRadius: "8px",
                  }}
                />
                <Box sx={{ textAlign: "right", mt: 1 }}>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={handleForgotPassword}
                    sx={{
                      color: theme.palette.bronzeShades.bronze3,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Forgot Password?
                  </Link>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "center",
                padding: theme.spacing(2),
              }}
            >
              <Button
                onClick={handleClose}
                sx={{
                  backgroundColor: theme.palette.bronzeShades.bronze3,
                  color: theme.palette.silverShades.light,
                  "&:hover": {
                    backgroundColor: theme.palette.bronzeShades.bronze4,
                  },
                }}
              >
                Cancel
              </Button>

              <Button
                onClick={handleSubmit}
                sx={{
                  backgroundColor: theme.palette.bronzeShades.bronze2,
                  color: theme.palette.silverShades.light,
                  "&:hover": {
                    backgroundColor: theme.palette.bronzeShades.bronze1,
                  },
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color={theme.palette.silverShades.light} /> : "Login"}
              </Button>
            </DialogActions>
          </>
        ) : (
          <ForgotPassword onBack={handleBackToLogin} />
        )}
      </Dialog>

      <ToastContainer closeOnClick pauseOnFocusLoss draggable pauseOnHover theme="dark" />
    </>
  );
}

export default LoginForm;
