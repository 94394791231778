// src/redux/slices/adminSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const get__customer_details = createAsyncThunk("admin/get__customer_details", async (customer_uuid, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/website/get-customer-details`, { customer_uuid });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data.message || "Failed to fetch customer details.");
  }
});

export const approve__customer_detail = createAsyncThunk("admin/approve__customer_detail", async ({ customer_uuid, part, id, message }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/website/approve-customer-detail`, {
      customer_uuid,
      part,
      id,
      message,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data.message || `Failed to approve ${part}.`);
  }
});
export const reject__customer_detail = createAsyncThunk("admin/reject__customer_detail", async ({ customer_uuid, id, part, message }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/website/reject-customer-detail`, {
      customer_uuid,
      part,
      id,
      message,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data.message || `Failed to reject ${part}.`);
  }
});

export const update__customer = createAsyncThunk("admin/update__customer", async ({ customer_uuid, data }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/website/update-customer`, {
      customer_uuid,
      data,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data.message || `Failed to update-customer`);
  }
});
export const update__customer_document = createAsyncThunk("admin/update__customer_document", async ({ formData }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/website/update-customer-document`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data.message || `Failed to update-document`);
  }
});
export const update__customer_representative = createAsyncThunk("admin/update__customer_representative", async ({ representative_uuid, data }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/website/update-customer-representative`, {
      representative_uuid,
      data,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data.message || `Failed to update-representative`);
  }
});
export const update__complex = createAsyncThunk("admin/update__complex", async ({ complex_uuid, data }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/website/update-complex`, {
      complex_uuid,
      data,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data.message || `Failed to update-complex`);
  }
});

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    loading: false,
    error: null,
    customerDetails: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(get__customer_details.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(get__customer_details.fulfilled, (state, action) => {
        state.loading = false;
        state.customerDetails = action.payload;
      })
      .addCase(get__customer_details.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(approve__customer_detail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approve__customer_detail.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally, update customerDetails based on the response
      })
      .addCase(approve__customer_detail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(reject__customer_detail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(reject__customer_detail.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally, update customerDetails based on the response
      })
      .addCase(reject__customer_detail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(update__customer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(update__customer.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally, update customerDetails based on the response
      })
      .addCase(update__customer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(update__customer_document.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(update__customer_document.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally, update customerDetails based on the response
      })
      .addCase(update__customer_document.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(update__customer_representative.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(update__customer_representative.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally, update customerDetails based on the response
      })
      .addCase(update__customer_representative.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(update__complex.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(update__complex.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally, update customerDetails based on the response
      })
      .addCase(update__complex.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default adminSlice.reducer;
