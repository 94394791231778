// src/components/RegisterIndividual.jsx

import React, { useState, useRef } from "react";
import { Box, Button, Stepper, Step, StepLabel, Typography, Grid, Flex } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../../redux/slices/authSlice";

import CircularProgress from "@mui/material/CircularProgress";

import CustomTextField from "../../../components/fields/CustomTextField";
import CustomSelect from "../../../components/fields/CustomSelect";
import CustomFileUpload from "../../../components/fields/CustomFileUpload";
import CustomCheckbox from "../../../components/fields/CustomCheckbox";
import CustomNumberField from "../../../components/fields/CustomNumberField";

import { useNavigate } from "react-router-dom";

import { LoadScript, Autocomplete } from "@react-google-maps/api";

const steps = ["Individual Details", "Representative Details", "Meter Installation Address", "Communication Preferences", "Paperwork Checklist", "Acceptance of Terms", "Submit"];

const RegisterIndividual = ({ onBackToUserTypeSelection }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    individual: {
      fullName: "",
      surname: "",
      idNumber: "",
      email: "",
      cell: "",
    },
    representative: {
      hasRepresentative: false,
      fullName: "",
      surname: "",
      idNumber: "",
      email: "",
      cell: "",
    },
    addresses: [
      {
        complex_name: "",
        complex_address1: "",
        complex_address2: "",
        complex_postalcode: "",
        complex_province: "",
      },
    ],
    communication: {
      landlord: true,
      representative: false,
    },
    documents: {
      municipalBill: [],
      idPassport: [],
      representativeIdPassport: [],
      bankLetter: [],
    },
    termsAccepted: false,
  });

  const [currentAddressIndex, setCurrentAddressIndex] = useState(0);
  const [numberOfAddresses, setNumberOfAddresses] = useState(1);

  const autocompleteRef = useRef(null);

  const [errors, setErrors] = useState({});

  const handleNext = () => {
    if (validateStep(activeStep)) {
      if (activeStep === 0 && !formData.representative.hasRepresentative) {
        setActiveStep((prev) => prev + 2);
      } else if (activeStep === 2 && currentAddressIndex < numberOfAddresses - 1) {
        setCurrentAddressIndex((prev) => prev + 1);
      } else {
        if (activeStep === 2 && currentAddressIndex === numberOfAddresses - 1) {
          setCurrentAddressIndex(0);
        }
        setActiveStep((prev) => prev + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      onBackToUserTypeSelection();
    } else if (activeStep === 1 && !formData.representative.hasRepresentative) {
      setActiveStep((prev) => prev - 2);
    } else if (activeStep === 2 && currentAddressIndex > 0) {
      setCurrentAddressIndex((prev) => prev - 1);
    } else {
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleChange = (e, section, field) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (section === "addresses") {
      setFormData((prev) => {
        const updatedAddresses = [...prev.addresses];
        updatedAddresses[currentAddressIndex] = {
          ...updatedAddresses[currentAddressIndex],
          [field]: value,
        };
        return {
          ...prev,
          addresses: updatedAddresses,
        };
      });

      setErrors((prev) => {
        const updatedErrors = { ...prev };
        updatedErrors.addresses = updatedErrors.addresses || [];
        updatedErrors.addresses[currentAddressIndex] = {
          ...updatedErrors.addresses[currentAddressIndex],
          [field]: "",
        };
        return updatedErrors;
      });
    } else {
      setFormData((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: value,
        },
      }));

      setErrors((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: "",
        },
      }));
    }
  };

  const handleFileChange = (e, docType) => {
    const files = e.target.files;
    setFormData((prev) => ({
      ...prev,
      documents: {
        ...prev.documents,
        [docType]: files,
      },
    }));

    setErrors((prev) => ({
      ...prev,
      documents: {
        ...prev.documents,
        [docType]: "",
      },
    }));
  };

  const validateStep = (step) => {
    let tempErrors = {};
    let key = "";
    switch (step) {
      case 0:
        tempErrors.individual = {};
        key = "individual";
        if (!formData.individual.fullName.trim()) {
          tempErrors.individual.fullName = "Full Name is required.";
        }
        if (!formData.individual.surname.trim()) {
          tempErrors.individual.surname = "Surname is required.";
        }
        if (!formData.individual.idNumber.trim()) {
          tempErrors.individual.idNumber = "ID Number is required.";
        }
        if (!formData.individual.email.trim()) {
          tempErrors.individual.email = "Email is required.";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.individual.email)) {
          tempErrors.individual.email = "Invalid email address.";
        }
        if (!formData.individual.cell.trim()) {
          tempErrors.individual.cell = "Cell Number is required.";
        }
        break;

      case 1:
        if (formData.representative.hasRepresentative) {
          tempErrors.representative = {};
          key = "representative";
          if (!formData.representative.fullName.trim()) {
            tempErrors.representative.fullName = "Representative Full Name is required.";
          }
          if (!formData.representative.surname.trim()) {
            tempErrors.representative.surname = "Representative Surname is required.";
          }
          if (!formData.representative.idNumber.trim()) {
            tempErrors.representative.idNumber = "Representative ID Number is required.";
          }
          if (!formData.representative.email.trim()) {
            tempErrors.representative.email = "Representative Email is required.";
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.representative.email)) {
            tempErrors.representative.email = "Invalid email address.";
          }
          if (!formData.representative.cell.trim()) {
            tempErrors.representative.cell = "Representative Cell Number is required.";
          }
        }
        break;

      case 2:
        tempErrors.addresses = {};
        key = "addresses";
        const currentAddress = formData.addresses[currentAddressIndex];
        let addressErrors = {};
        if (!currentAddress.complex_name.trim()) {
          addressErrors.complex_name = "Complex Name is required.";
        }
        if (!currentAddress.complex_address1.trim()) {
          addressErrors.complex_address1 = "Address Line 1 is required.";
        }
        if (!currentAddress.complex_postalcode.trim()) {
          addressErrors.complex_postalcode = "Postal Code is required.";
        }
        if (!currentAddress.complex_province.trim()) {
          addressErrors.complex_province = "Province is required.";
        }
        tempErrors.addresses[currentAddressIndex] = addressErrors;
        break;

      case 3:
        key = "communication";
        break;

      case 4:
        tempErrors.documents = {};
        key = "documents";

        if (formData.documents.municipalBill.length === 0) {
          tempErrors.documents.municipalBill = "Municipal/Eskom Bill is required.";
        }

        if (formData.documents.idPassport.length === 0) {
          tempErrors.documents.idPassport = "ID or Passport is required.";
        }

        if (formData.documents.bankLetter.length === 0) {
          tempErrors.documents.bankLetter = "Bank confirmation letter is required.";
        }

        if (formData.representative.hasRepresentative && formData.documents.representativeIdPassport.length === 0) {
          tempErrors.documents.representativeIdPassport = "Representative ID or Passport is required.";
        }
        break;

      case 5:
        key = "termsAccepted";
        if (!formData.termsAccepted) {
          tempErrors.termsAccepted = "You must accept the Terms and Conditions.";
        }
        break;

      default:
        break;
    }
    setErrors(tempErrors);
    console.log({ step, formData, tempErrors });
    let noErrors = false;
    if (Object(tempErrors).hasOwnProperty(key)) {
      if (!Object.keys(tempErrors[key]).length) {
        noErrors = true;
      } else if (key == "addresses") {
        noErrors = true;
        for (const address of Object.values(tempErrors[key])) {
          if (Object.keys(address).length) {
            noErrors = false;
          }
        }
      }

      if (tempErrors[key].hasOwnProperty("representative")) {
        if (!Object.keys(tempErrors[key]["representative"]).length) {
          noErrors = true;
        } else {
          noErrors = false;
        }
      }
    } else {
      noErrors = true;
    }
    return noErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateStep(activeStep)) {
      if (activeStep === steps.length - 1) {
        const submissionData = new FormData();

        submissionData.append("individual_fullName", formData.individual.fullName);
        submissionData.append("individual_surname", formData.individual.surname);
        submissionData.append("individual_idNumber", formData.individual.idNumber);
        submissionData.append("individual_email", formData.individual.email);
        submissionData.append("individual_cell", formData.individual.cell);
        submissionData.append("representative_hasRepresentative", formData.representative.hasRepresentative);

        if (formData.representative.hasRepresentative) {
          submissionData.append("representative_fullName", formData.representative.fullName);
          submissionData.append("representative_surname", formData.representative.surname);
          submissionData.append("representative_idNumber", formData.representative.idNumber);
          submissionData.append("representative_email", formData.representative.email);
          submissionData.append("representative_cell", formData.representative.cell);
        }

        formData.addresses.forEach((address, index) => {
          submissionData.append(`addresses_${index}_complex_name`, address.complex_name);
          submissionData.append(`addresses_${index}_complex_address1`, address.complex_address1);
          submissionData.append(`addresses_${index}_complex_address2`, address.complex_address2);
          submissionData.append(`addresses_${index}_complex_postalcode`, address.complex_postalcode);
          submissionData.append(`addresses_${index}_complex_province`, address.complex_province);
        });

        submissionData.append("communication_landlord", formData.communication.landlord);
        submissionData.append("communication_representative", formData.communication.representative);

        Array.from(formData.documents.municipalBill).forEach((file) => {
          submissionData.append("documents.municipalBill", file);
        });
        Array.from(formData.documents.idPassport).forEach((file) => {
          submissionData.append("documents.idPassport", file);
        });
        Array.from(formData.documents.representativeIdPassport).forEach((file) => {
          submissionData.append("documents.representativeIdPassport", file);
        });
        Array.from(formData.documents.bankLetter).forEach((file) => {
          submissionData.append("documents.bankLetter", file);
        });

        submissionData.append("termsAccepted", formData.termsAccepted);

        dispatch(registerUser(submissionData))
          .unwrap()
          .then((response) => {
            setActiveStep((prev) => prev + 1);
          })
          .catch((error) => {
            console.error("Registration failed:", error);
          });
      } else {
        handleNext();
      }
    }
  };

  const parseAddressComponents = (place) => {
    const addressComponents = place.address_components;
    const components = {
      streetNumber: "",
      route: "",
      city: "",
      province: "",
      postalCode: "",
      country: "",
    };

    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes("street_number")) {
        components.streetNumber = component.long_name;
      }
      if (types.includes("route")) {
        components.route = component.long_name;
      }
      if (types.includes("locality")) {
        components.city = component.long_name;
      }
      if (types.includes("administrative_area_level_1")) {
        components.province = component.long_name;
      }
      if (types.includes("postal_code")) {
        components.postalCode = component.long_name;
      }
      if (types.includes("country")) {
        components.country = component.long_name;
      }
    });

    return {
      streetAddress: `${components.streetNumber} ${components.route}`.trim(),
      city: components.city,
      province: components.province,
      postalCode: components.postalCode,
      country: components.country,
    };
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Individual Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CustomTextField label="Full Name" name="fullName" variant="outlined" fullWidth required value={formData.individual.fullName} onChange={(e) => handleChange(e, "individual", "fullName")} error={!!errors.individual?.fullName} helperText={errors.individual?.fullName} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField label="Surname" name="surname" variant="outlined" fullWidth required value={formData.individual.surname} onChange={(e) => handleChange(e, "individual", "surname")} error={!!errors.individual?.surname} helperText={errors.individual?.surname} />
              </Grid>
            </Grid>

            <CustomTextField label="ID Number" name="idNumber" variant="outlined" fullWidth required margin="normal" value={formData.individual.idNumber} onChange={(e) => handleChange(e, "individual", "idNumber")} error={!!errors.individual?.idNumber} helperText={errors.individual?.idNumber} />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CustomTextField label="Email" name="email" type="email" variant="outlined" fullWidth required margin="normal" value={formData.individual.email} onChange={(e) => handleChange(e, "individual", "email")} error={!!errors.individual?.email} helperText={errors.individual?.email} />{" "}
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField label="Cell" name="cell" type="text" variant="outlined" fullWidth required margin="normal" value={formData.individual.cell} onChange={(e) => handleChange(e, "individual", "cell")} error={!!errors.individual?.cell} helperText={errors.individual?.cell} />{" "}
              </Grid>
            </Grid>

            <CustomCheckbox label="Do you have an additional representative?" checked={formData.representative.hasRepresentative} onChange={(e) => handleChange(e, "representative", "hasRepresentative")} />
          </Box>
        );
      case 1:
        if (formData.representative.hasRepresentative) {
          return (
            <Box>
              <Typography variant="h6" gutterBottom>
                Representative Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <CustomTextField label="Full Name" name="fullName" variant="outlined" fullWidth required value={formData.representative.fullName} onChange={(e) => handleChange(e, "representative", "fullName")} error={!!errors.representative?.fullName} helperText={errors.representative?.fullName} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField label="Surname" name="surname" variant="outlined" fullWidth required value={formData.representative.surname} onChange={(e) => handleChange(e, "representative", "surname")} error={!!errors.representative?.surname} helperText={errors.representative?.surname} />
                </Grid>
              </Grid>

              <CustomTextField label="ID Number" name="idNumber" variant="outlined" fullWidth required margin="normal" value={formData.representative.idNumber} onChange={(e) => handleChange(e, "representative", "idNumber")} error={!!errors.representative?.idNumber} helperText={errors.representative?.idNumber} />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <CustomTextField label="Email" name="email" type="email" variant="outlined" fullWidth required margin="normal" value={formData.representative.email} onChange={(e) => handleChange(e, "representative", "email")} error={!!errors.representative?.email} helperText={errors.representative?.email} />{" "}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField label="Cell" name="cell" type="text" variant="outlined" fullWidth required margin="normal" value={formData.representative.cell} onChange={(e) => handleChange(e, "representative", "cell")} error={!!errors.representative?.cell} helperText={errors.representative?.cell} />{" "}
                </Grid>
              </Grid>
            </Box>
          );
        } else {
          handleNext();
          return null;
        }
      case 2:
        return (
          <Box>
            <Typography variant="h5" gutterBottom>
              Meter Installation Address ({currentAddressIndex + 1} of {numberOfAddresses})
            </Typography>

            {currentAddressIndex === 0 && (
              <Box display="flex" flexDirection="row" gap="2%">
                <Box width="49%"></Box>
                <Box width="49%">
                  <CustomNumberField
                    label="Number of Addresses"
                    name="numberOfAddresses"
                    variant="outlined"
                    fullWidth
                    required
                    margin="auto"
                    value={numberOfAddresses}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      setNumberOfAddresses(value > 0 ? value : 1);
                      setFormData((prev) => {
                        const addresses = [...prev.addresses];
                        while (addresses.length < value) {
                          addresses.push({
                            complex_name: "",
                            complex_address1: "",
                            complex_address2: "",
                            complex_postalcode: "",
                            complex_province: "",
                          });
                        }
                        while (addresses.length > value) {
                          addresses.pop();
                        }
                        return {
                          ...prev,
                          addresses: addresses,
                        };
                      });
                    }}
                  />
                </Box>
              </Box>
            )}

            <Box display="flex" flexDirection="row" gap="2px">
              <Box width="49%">
                <LoadScript googleMapsApiKey="AIzaSyCnFHgXLDRM4h8SFqCYHeHXjvshStr7IKg" libraries={["places"]}>
                  <Autocomplete
                    onLoad={(autocomplete) => {
                      autocompleteRef.current = autocomplete;
                    }}
                    onPlaceChanged={() => {
                      const place = autocompleteRef.current.getPlace();
                      const address = parseAddressComponents(place);
                      setFormData((prev) => {
                        const updatedAddresses = [...prev.addresses];
                        updatedAddresses[currentAddressIndex] = {
                          ...updatedAddresses[currentAddressIndex],
                          complex_address1: address.streetAddress,
                          complex_address2: address.city,
                          complex_postalcode: address.postalCode,
                          complex_province: address.province,
                        };
                        return {
                          ...prev,
                          addresses: updatedAddresses,
                        };
                      });
                    }}
                  >
                    <CustomTextField label="Google Maps Address" name="complex_address1" variant="outlined" fullWidth margin="normal" value={formData.addresses[currentAddressIndex]?.complex_address1 || ""} onChange={(e) => handleChange(e, "addresses", "complex_address1")} error={!!errors.addresses?.[currentAddressIndex]?.complex_address1} helperText={errors.addresses?.[currentAddressIndex]?.complex_address1} autoComplete="new-password" spellCheck="false" autoCorrect="off" />
                  </Autocomplete>
                </LoadScript>
              </Box>
              <Box width="49%">
                <CustomTextField autoComplete="new-password" label="Complex Name" name="complex_name" variant="outlined" fullWidth required margin="normal" value={formData.addresses[currentAddressIndex]?.complex_name || ""} onChange={(e) => handleChange(e, "addresses", "complex_name")} error={!!errors.addresses?.[currentAddressIndex]?.complex_name} helperText={errors.addresses?.[currentAddressIndex]?.complex_name} />
              </Box>
            </Box>

            <Box display="flex" flexDirection="row" gap="2px">
              <CustomTextField autoComplete="new-password" label="Address Line 1" name="complex_address1" variant="outlined" fullWidth required margin="normal" value={formData.addresses[currentAddressIndex]?.complex_address1 || ""} onChange={(e) => handleChange(e, "addresses", "complex_address1")} error={!!errors.addresses?.[currentAddressIndex]?.complex_address1} helperText={errors.addresses?.[currentAddressIndex]?.complex_address1} />
              <CustomTextField autoComplete="new-password" label="Address Line 2" name="complex_address2" variant="outlined" fullWidth margin="normal" value={formData.addresses[currentAddressIndex]?.complex_address2 || ""} onChange={(e) => handleChange(e, "addresses", "complex_address2")} error={!!errors.addresses?.[currentAddressIndex]?.complex_address2} helperText={errors.addresses?.[currentAddressIndex]?.complex_address2} />
            </Box>

            <Box display="flex" flexDirection="row" gap="2px">
              <CustomTextField autoComplete="new-password" label="Postal Code" name="complex_postalcode" variant="outlined" fullWidth required margin="normal" value={formData.addresses[currentAddressIndex]?.complex_postalcode || ""} onChange={(e) => handleChange(e, "addresses", "complex_postalcode")} error={!!errors.addresses?.[currentAddressIndex]?.complex_postalcode} helperText={errors.addresses?.[currentAddressIndex]?.complex_postalcode} />
              <CustomSelect
                label="Province"
                name="complex_province"
                value={formData.addresses[currentAddressIndex]?.complex_province || ""}
                onChange={(e) => handleChange(e, "addresses", "complex_province")}
                options={[
                  { value: "", label: "Select Province" },
                  { value: "Gauteng", label: "Gauteng" },
                  { value: "Western Cape", label: "Western Cape" },
                  { value: "Eastern Cape", label: "Eastern Cape" },
                  { value: "Northern Cape", label: "Northern Cape" },
                  { value: "KwaZulu-Natal", label: "KwaZulu-Natal" },
                  { value: "Free State", label: "Free State" },
                  { value: "Limpopo", label: "Limpopo" },
                  { value: "Mpumalanga", label: "Mpumalanga" },
                  { value: "North West", label: "North West" },
                ]}
                error={!!errors.addresses?.[currentAddressIndex]?.complex_province}
                helperText={errors.addresses?.[currentAddressIndex]?.complex_province}
                required
              />
            </Box>
          </Box>
        );
      case 3:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Communication Preferences
            </Typography>
            <CustomCheckbox label="Landlord" checked={formData.communication.landlord} onChange={(e) => handleChange(e, "communication", "landlord")} />
            <CustomCheckbox label="Representative" checked={formData.communication.representative} onChange={(e) => handleChange(e, "communication", "representative")} />
          </Box>
        );
      case 4:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Paperwork Checklist
            </Typography>
            <CustomFileUpload label="Copy of latest Municipal/Eskom Bill" id="municipal-bill-upload" onChange={(e) => handleFileChange(e, "municipalBill")} files={formData.documents.municipalBill} error={errors.documents?.municipalBill} />
            <CustomFileUpload label="Copy of ID or Passport" id="id-passport-upload" onChange={(e) => handleFileChange(e, "idPassport")} files={formData.documents.idPassport} error={errors.documents?.idPassport} />
            {formData.representative.hasRepresentative && <CustomFileUpload label="Copy of Representative ID or Passport" id="rep-id-passport-upload" onChange={(e) => handleFileChange(e, "representativeIdPassport")} files={formData.documents.representativeIdPassport} error={errors.documents?.representativeIdPassport} />}
            <CustomFileUpload label="Bank confirmation letter (not older than 3 months)" id="bank-letter-upload" onChange={(e) => handleFileChange(e, "bankLetter")} files={formData.documents.bankLetter} error={errors.documents?.bankLetter} />
          </Box>
        );
      case 5:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Acceptance of Terms and Conditions
            </Typography>
            <CustomCheckbox
              label="I accept the Terms and Conditions"
              checked={formData.termsAccepted}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  termsAccepted: e.target.checked,
                }))
              }
            />
            {errors.termsAccepted && (
              <Typography variant="body2" color="error">
                {errors.termsAccepted}
              </Typography>
            )}
            <Typography variant="body2" sx={{ mt: 2 }}>
              By submitting a completed Meter Minds Registration Form (or by supplying registration details) you warrant that you have READ, UNDERSTOOD and ACCEPT the Meter Minds Service Agreement, Terms and Conditions in attached documents.
            </Typography>
            <Button
              variant="text"
              onClick={() => window.open("/terms", "_blank")}
              sx={{
                mt: 1,
                textTransform: "none",
                color: theme.palette.secondary.main,
                "&:hover": {
                  textDecoration: "underline",
                  backgroundColor: "transparent",
                },
              }}
            >
              View Terms and Conditions
            </Button>
          </Box>
        );
      case 6:
        return (
          <Box textAlign="center" p={4}>
            {loading ? (
              // Display loader while registration is in progress
              <CircularProgress color={theme.palette.silverShades.light} />
            ) : error ? (
              // Display error message if registration failed
              <>
                <Typography variant="h5" gutterBottom color="error">
                  Registration Unsuccessful
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Are you sure you haven't registered before?
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => navigate("/")}
                  sx={{
                    mt: 3,
                    backgroundColor: theme.palette.bronzeShades.bronze2,
                    color: theme.palette.silverShades.light,
                    "&:hover": {
                      backgroundColor: theme.palette.bronzeShades.bronze1,
                    },
                  }}
                >
                  Go to Home
                </Button>
              </>
            ) : // Optionally, you can display nothing or a retry button here
            null}
          </Box>
        );
      default:
        return "Unknown Step";
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "800px",
        margin: "0 auto",
        background: "rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(10px)",
        boxShadow: `0 8px 32px 0 ${theme.palette.blackShades.dark}`,
        borderRadius: "16px",
        border: `1px solid ${theme.palette.blackShades.light}`,
        color: theme.palette.silverShades.light,
        p: { xs: 2, sm: 4 },
      }}
    >
      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 1 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconProps={{
                style: { color: theme.palette.bronzeShades.bronze2 },
              }}
              sx={{
                "& .MuiStepLabel-label": {
                  color: theme.palette.silverShades.dark,
                },
                "& .MuiStepLabel-label.Mui-active": {
                  color: theme.palette.silverShades.light,
                  fontWeight: "bold",
                },
                "& .MuiStepLabel-label.Mui-completed": {
                  color: theme.palette.bronzeShades.bronze4,
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length ? (
        <Box display="flex" flexDirection="column" gap="15px">
          <Typography variant="h6" align="center">
            Thank you for registering! We've sent you a verification email.
          </Typography>
          <Button
            variant="contained"
            onClick={() => (window.location.href = "/")}
            sx={{
              backgroundColor: theme.palette.bronzeShades.bronze4,
              color: theme.palette.silverShades.light,
              "&:hover": {
                backgroundColor: theme.palette.bronzeShades.bronze1,
              },
            }}
          >
            Home
          </Button>
        </Box>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            {renderStepContent(activeStep)}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 4,
              }}
            >
              <Button
                onClick={handleBack}
                sx={{
                  backgroundColor: theme.palette.bronzeShades.bronze3,
                  color: theme.palette.silverShades.light,
                  "&:hover": {
                    backgroundColor: theme.palette.bronzeShades.bronze4,
                  },
                }}
              >
                Back
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  type="submit"
                  // onClick={() => (window.location.href = "/")}
                  sx={{
                    backgroundColor: theme.palette.bronzeShades.bronze2,
                    color: theme.palette.silverShades.light,
                    "&:hover": {
                      backgroundColor: theme.palette.bronzeShades.bronze1,
                    },
                    display: "none",
                  }}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{
                    backgroundColor: theme.palette.bronzeShades.bronze2,
                    color: theme.palette.silverShades.light,
                    "&:hover": {
                      backgroundColor: theme.palette.bronzeShades.bronze1,
                    },
                  }}
                >
                  Next
                </Button>
              )}
            </Box>
          </form>
        </>
      )}
    </Box>
  );
};

export default RegisterIndividual;
