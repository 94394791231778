import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, useTheme, useMediaQuery, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useOutletContext } from "react-router-dom";
import CustomPageTitle from "../../../../components/custom/CustomTitle";
import CustomButton from "../../../../components/fields/CustomButton";
import ScrollProgressBar from "../../../../components/custom/ScrollProgressBar";

import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SecurityIcon from "@mui/icons-material/Security";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import HouseIcon from "@mui/icons-material/House";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DesignServicesIcon from "@mui/icons-material/DesignServices";

const StyledSlide = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  backdropFilter: "blur(6px)",
  background: "transparent",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  color: theme.palette.silverShades.light,
  textAlign: "left",
  padding: theme.spacing(4),
  borderTop: `1px inset ${theme.palette.silverShades.light}`,
  marginBottom: "25px",
  overflow: "hidden",
}));

const StyledCardBox = styled(Box)(({ theme, bgColor }) => ({
  position: "absolute",
  top: "-15px",
  left: "-15px",
  height: "80px",
  width: "80px",
  borderRadius: "50%",
  backgroundColor: bgColor || theme.palette.blackShades.dark,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: `0 4px 6px rgba(0,0,0,0.1)`,
  zIndex: 1,
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.1)",
  },
  [theme.breakpoints.down("sm")]: {
    height: "60px",
    width: "60px",
    top: "-15px",
    right: "-15px",
  },
}));

const CardIcon = styled(({ icon: IconComponent, ...other }) => <IconComponent {...other} />)(({ theme }) => ({
  color: theme.palette.silverShades.dark,
  fontSize: "36px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "28px",
  },
}));

const popups = [
  {
    title: "Accuracy",
    content: "Tenants pay upfront for what they use.",
  },
  {
    title: "Cost Control",
    content:
      "By monitoring individual unit consumption, landlords can identify inefficiencies and make informed decisions to reduce overall utility costs.",
  },
  {
    title: "Enhanced Tenant Accountability",
    content:
      "Submetering encourages tenants to be mindful of their utility usage, promoting conservation and responsible behavior.",
  },
  {
    title: "Streamlined Management",
    content:
      "Automated data collection and reporting simplify the billing process, saving time and reducing administrative burdens.",
  },
  {
    title: "Improved Cash Flow",
    content:
      "With accurate billing and reduced disputes, landlords can expect more consistent cash flow from utility payments.",
  },
  {
    title: "Increased Property Value",
    content:
      "Properties with efficient utility management features are often more attractive to potential tenants and can command higher rents or resale values.",
  },
  {
    title: "Transparency",
    content:
      "Clear, detailed usage data fosters trust between landlords and tenants, enhancing tenant satisfaction and retention.",
  },
];

const getCardDetails = (index, theme) => {
  switch (index % 7) {
    case 0:
      return { icon: PrecisionManufacturingIcon, bgColor: theme.palette.blackShades.medium };
    case 1:
      return { icon: AttachMoneyIcon, bgColor: theme.palette.blackShades.medium };
    case 2:
      return { icon: SecurityIcon, bgColor: theme.palette.blackShades.medium };
    case 3:
      return { icon: ManageAccountsIcon, bgColor: theme.palette.blackShades.medium };
    case 4:
      return { icon: AccountBalanceWalletIcon, bgColor: theme.palette.blackShades.medium };
    case 5:
      return { icon: HouseIcon, bgColor: theme.palette.blackShades.medium };
    case 6:
      return { icon: VisibilityIcon, bgColor: theme.palette.blackShades.medium };
    default:
      return { icon: DesignServicesIcon, bgColor: theme.palette.blackShades.medium };
  }
};

function PropertyOwners() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [buttonTop, setButtonTop] = useState("150px");
  const isScrolled = buttonTop === "80px";
  const sentinelRef = useRef(null);
  const { contentRef } = useOutletContext();

  useEffect(() => {
    const currentSentinel = sentinelRef.current;

    if (!currentSentinel) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setButtonTop("80px");
        } else {
          setButtonTop("150px");
        }
      },
      { root: null, threshold: 0 }
    );

    observer.observe(currentSentinel);

    return () => {
      observer.unobserve(currentSentinel);
      observer.disconnect();
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "rgb(20, 20, 20)",
        backgroundImage:
          "linear-gradient(90deg, rgba(20, 20, 20, 1) 0%, rgba(40, 40, 40, 1) 35%, rgba(60, 60, 60, 1) 100%)",
        color: theme.palette.silverShades.light,
        padding: theme.spacing(1, 4),
        minHeight: "100vh",
      }}
    >
      <div ref={sentinelRef} />

      <CustomPageTitle
        icon={PrecisionManufacturingIcon}
        tooltip="Benefits for Property Owners"
        title="Benefits for Property Owners"
        sx={{
          textAlign: "center",
        }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: buttonTop,
          left: 0,
          right: 0,
          zIndex: 1000,
          transition: "top 0.3s ease-in-out",
        }}
      >
        {/* Back Button */}
        <IconButton
          onClick={() => navigate("/services/utility-sub-metering")}
          sx={{
            position: "absolute",
            left: "50px",
            color: isScrolled ? theme.palette.silverShades.dark : theme.palette.bronzeShades.bronze1,
            backgroundColor: "transparent",
            "&:hover": {
              color: isScrolled ? theme.palette.bronzeShades.bronze3 : theme.palette.bronzeShades.bronze5,
            },
          }}
        >
          <ArrowBackIcon sx={{ fontSize: "2.5rem" }} />
        </IconButton>

        {/* Scroll Progress Bar */}
        <ScrollProgressBar contentRef={contentRef} />

        {/* "Get in Touch" Button */}
        <Box
          sx={{
            position: "absolute",
            right: "40px",
          }}
        >
          <CustomButton link="/contact-us" text="Get in Touch" />
        </Box>
      </Box>

      {popups.map((popup, index) => {
        const { icon: IconComponent, bgColor } = getCardDetails(index, theme);

        return (
          <StyledSlide key={index}>
            <StyledCardBox bgColor={bgColor} aria-hidden="true">
              <CardIcon icon={IconComponent} />
            </StyledCardBox>

            <Box sx={{ width: "100%" }}>
              <Typography
                variant={isSmallScreen ? "h5" : "h4"}
                sx={{
                  color: theme.palette.bronzeShades.bronze1,
                  fontWeight: "bold",
                  textShadow: "1px 1px 2px black",
                  mb: 2,
                  pl: theme.spacing(8),
                }}
              >
                {popup.title}
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.silverShades.light,
                  textShadow: "1px 1px 2px black",
                  pl: theme.spacing(8),
                  pr: theme.spacing(4),
                }}
              >
                {popup.content}
              </Typography>
            </Box>
          </StyledSlide>
        );
      })}
    </Box>
  );
}

export default PropertyOwners;
