// src/components/specific/Complex.jsx

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  approve__customer_detail,
  reject__customer_detail,
  update__complex, // Ensure this action is defined in your Redux slice
} from "../../../../redux/slices/admin"; // Ensure the correct path
import { toast } from "react-toastify";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import CustomTextField from "../../../../components/fields/CustomTextField";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { useTheme } from "@mui/material/styles";

const Complex = ({ complexes, customer_uuid }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [approvalMessages, setApprovalMessages] = useState({});
  const [actionStatus, setActionStatus] = useState({}); // { [complex_uuid]: 'approved' | 'rejected' }
  const [loadingStatus, setLoadingStatus] = useState({}); // { [complex_uuid]: boolean }

  // New state for editing
  const [editStatus, setEditStatus] = useState({}); // { [complex_uuid]: boolean }
  const [editedComplexes, setEditedComplexes] = useState(
    complexes.reduce((acc, complex) => {
      acc[complex.complex_uuid] = { ...complex };
      return acc;
    }, {})
  );

  const handleMessageChange = (complex_uuid, value) => {
    setApprovalMessages((prev) => ({
      ...prev,
      [complex_uuid]: value,
    }));
  };

  const getPartName = () => "Complex Details";

  const handleApprove = async (complex_uuid) => {
    try {
      setLoadingStatus((prev) => ({ ...prev, [complex_uuid]: true }));
      const resultAction = await dispatch(
        approve__customer_detail({
          customer_uuid,
          part: "complex",
          id: complex_uuid,
          message: approvalMessages[complex_uuid] || "",
        })
      );

      if (approve__customer_detail.fulfilled.match(resultAction)) {
        toast.success(`${getPartName()} approved successfully.`);
        setActionStatus((prev) => ({ ...prev, [complex_uuid]: "approved" }));
      } else {
        toast.error(resultAction.payload || `Failed to approve ${getPartName()}.`);
      }
    } catch (err) {
      console.error(`Error approving complex ${complex_uuid}:`, err);
      toast.error(`An unexpected error occurred while approving ${getPartName()}.`);
    } finally {
      setLoadingStatus((prev) => ({ ...prev, [complex_uuid]: false }));
    }
  };

  const handleReject = async (complex_uuid) => {
    try {
      setLoadingStatus((prev) => ({ ...prev, [complex_uuid]: true }));
      const resultAction = await dispatch(
        reject__customer_detail({
          customer_uuid,
          part: "complex",
          id: complex_uuid,
          message: approvalMessages[complex_uuid] || "",
        })
      );

      if (reject__customer_detail.fulfilled.match(resultAction)) {
        toast.success(`${getPartName()} rejected successfully.`);
        setActionStatus((prev) => ({ ...prev, [complex_uuid]: "rejected" }));
      } else {
        toast.error(resultAction.payload || `Failed to reject ${getPartName()}.`);
      }
    } catch (err) {
      console.error(`Error rejecting complex ${complex_uuid}:`, err);
      toast.error(`An unexpected error occurred while rejecting ${getPartName()}.`);
    } finally {
      setLoadingStatus((prev) => ({ ...prev, [complex_uuid]: false }));
    }
  };

  // New handlers for editing
  const handleEdit = (complex_uuid) => {
    setEditStatus((prev) => ({ ...prev, [complex_uuid]: true }));
  };

  const handleCancelEdit = (complex_uuid) => {
    setEditStatus((prev) => ({ ...prev, [complex_uuid]: false }));
    setEditedComplexes((prev) => ({
      ...prev,
      [complex_uuid]: { ...complexes.find((c) => c.complex_uuid === complex_uuid) },
    }));
  };

  const handleInputChange = (complex_uuid, field, value) => {
    setEditedComplexes((prev) => ({
      ...prev,
      [complex_uuid]: {
        ...prev[complex_uuid],
        [field]: value,
      },
    }));
  };

  const handleSaveEdit = async (complex_uuid) => {
    const editedData = editedComplexes[complex_uuid];
    // Optionally, perform validation here

    try {
      setLoadingStatus((prev) => ({ ...prev, [complex_uuid]: true }));
      const resultAction = await dispatch(
        update__complex({
          complex_uuid: complex_uuid,
          data: editedData,
        })
      );

      if (update__complex.fulfilled.match(resultAction)) {
        toast.success("Complex details updated successfully.");
        setEditStatus((prev) => ({ ...prev, [complex_uuid]: false }));
        // Optionally, update the local complexes data if necessary
        // This depends on how your Redux store and props are managed
      } else {
        toast.error(resultAction.payload || "Failed to update Complex details.");
      }
    } catch (err) {
      console.error(`Error updating complex ${complex_uuid}:`, err);
      toast.error("An unexpected error occurred while updating Complex details.");
    } finally {
      setLoadingStatus((prev) => ({ ...prev, [complex_uuid]: false }));
    }
  };

  return (
    <Box mb={4}>
      <Typography variant="h5" gutterBottom color="bronzeShades.bronze2">
        4. Complex Details
      </Typography>
      {complexes.length === 0 ? (
        <Typography variant="body1">No complexes associated with this customer.</Typography>
      ) : (
        complexes.map((complex) => (
          <Box
            key={complex.complex_uuid}
            mb={3}
            p={2}
            border={`1px solid ${theme.palette.silverShades.dark}`}
            borderRadius={2}
          >
            <Table sx={{ backgroundColor: theme.palette.silverShades.dark }}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <strong>Complex UUID</strong>
                  </TableCell>
                  <TableCell>{complex.complex_uuid}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Name</strong>
                  </TableCell>
                  <TableCell>
                    {editStatus[complex.complex_uuid] ? (
                      <CustomTextField
                        value={editedComplexes[complex.complex_uuid].complex_name}
                        onChange={(e) =>
                          handleInputChange(
                            complex.complex_uuid,
                            "complex_name",
                            e.target.value
                          )
                        }
                      />
                    ) : (
                      complex.complex_name
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Address Line 1</strong>
                  </TableCell>
                  <TableCell>
                    {editStatus[complex.complex_uuid] ? (
                      <CustomTextField
                        value={editedComplexes[complex.complex_uuid].complex_address1}
                        onChange={(e) =>
                          handleInputChange(
                            complex.complex_uuid,
                            "complex_address1",
                            e.target.value
                          )
                        }
                      />
                    ) : (
                      complex.complex_address1
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Address Line 2</strong>
                  </TableCell>
                  <TableCell>
                    {editStatus[complex.complex_uuid] ? (
                      <CustomTextField
                        value={editedComplexes[complex.complex_uuid].complex_address2}
                        onChange={(e) =>
                          handleInputChange(
                            complex.complex_uuid,
                            "complex_address2",
                            e.target.value
                          )
                        }
                      />
                    ) : (
                      complex.complex_address2
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Postal Code</strong>
                  </TableCell>
                  <TableCell>
                    {editStatus[complex.complex_uuid] ? (
                      <CustomTextField
                        value={editedComplexes[complex.complex_uuid].complex_postalcode}
                        onChange={(e) =>
                          handleInputChange(
                            complex.complex_uuid,
                            "complex_postalcode",
                            e.target.value
                          )
                        }
                      />
                    ) : (
                      complex.complex_postalcode
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Province</strong>
                  </TableCell>
                  <TableCell>
                    {editStatus[complex.complex_uuid] ? (
                      <CustomTextField
                        value={editedComplexes[complex.complex_uuid].complex_province}
                        onChange={(e) =>
                          handleInputChange(
                            complex.complex_uuid,
                            "complex_province",
                            e.target.value
                          )
                        }
                      />
                    ) : (
                      complex.complex_province
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box mt={2} display="flex" alignItems="center" gap={2}>
              {editStatus[complex.complex_uuid] ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSaveEdit(complex.complex_uuid)}
                    startIcon={<SaveIcon />}
                    disabled={loadingStatus[complex.complex_uuid]}
                    sx={{
                      backgroundColor: theme.palette.bronzeShades.bronze4,
                      color: theme.palette.silverShades.light,
                      "&:hover": {
                        backgroundColor: theme.palette.bronzeShades.bronze1,
                      },
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleCancelEdit(complex.complex_uuid)}
                    startIcon={<CancelPresentationIcon />}
                    disabled={loadingStatus[complex.complex_uuid]}
                    sx={{
                      borderColor: theme.palette.bronzeShades.bronze5,
                      color: theme.palette.silverShades.light,
                      "&:hover": {
                        borderColor: theme.palette.bronzeShades.bronze4,
                        backgroundColor: theme.palette.bronzeShades.bronze1,
                      },
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <CustomTextField
                    label="Message"
                    name="outlined"
                    fullWidth
                    value={approvalMessages[complex.complex_uuid] || ""}
                    onChange={(e) => handleMessageChange(complex.complex_uuid, e.target.value)}
                    placeholder="Optional message"
                  />
                  {actionStatus[complex.complex_uuid] === "approved" ? (
                    <Box display="flex" alignItems="center" color="green">
                      <CheckCircleIcon />
                      <Typography ml={1}>Verified</Typography>
                    </Box>
                  ) : actionStatus[complex.complex_uuid] === "rejected" ? (
                    <Box display="flex" alignItems="center" color="red">
                      <CancelIcon />
                      <Typography ml={1}>Rejected</Typography>
                    </Box>
                  ) : (
                    <Box display="flex" alignItems="center" gap={1}>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleApprove(complex.complex_uuid)}
                        disabled={loadingStatus[complex.complex_uuid] || false}
                        sx={{
                          backgroundColor: theme.palette.bronzeShades.bronze4,
                          color: theme.palette.silverShades.light,
                          "&:hover": {
                            backgroundColor: theme.palette.bronzeShades.bronze1,
                          },
                        }}
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleReject(complex.complex_uuid)}
                        disabled={loadingStatus[complex.complex_uuid] || false}
                        sx={{
                          backgroundColor: theme.palette.bronzeShades.bronze5,
                          color: theme.palette.silverShades.light,
                          "&:hover": {
                            backgroundColor: theme.palette.bronzeShades.bronze4,
                          },
                        }}
                      >
                        Reject
                      </Button>
                      <IconButton
                        color="primary"
                        onClick={() => handleEdit(complex.complex_uuid)}
                        aria-label="edit"
                      >
                        <EditIcon sx={{ color: "silverShades.dark" }}/>
                      </IconButton>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        ))
      )}
    </Box>
  );
};

export default Complex;
