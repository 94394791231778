// src/components/RegisterAdmin.jsx

import React, { useState } from "react";
import { Box, Button, Typography, Grid, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { registerAdmin } from "../../../redux/slices/authSlice"; // Ensure you have this action
import CustomTextField from "../../../components/fields/CustomTextField";
import { useNavigate } from "react-router-dom";

const RegisterAdmin = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
    cell: "",
    id: "",
    authorizationKey: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e, field) => {
    const value = e.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.username.trim()) tempErrors.username = "Username is required.";
    if (!formData.password) tempErrors.password = "Password is required.";
    else if (formData.password.length < 6) tempErrors.password = "Password must be at least 6 characters.";
    if (!formData.email.trim()) {
      tempErrors.email = "Email is required.";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      tempErrors.email = "Invalid email address.";
    }
    if (!formData.cell.trim()) tempErrors.cell = "Cell number is required.";
    if (!formData.id.trim()) tempErrors.id = "ID is required.";
    if (!formData.authorizationKey.trim()) tempErrors.authorizationKey = "Authorization Key is required.";

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const submissionData = {
        username: formData.username,
        password: formData.password,
        email: formData.email,
        cell: formData.cell,
        id: formData.id,
        authorizationKey: formData.authorizationKey,
      };

      dispatch(registerAdmin(submissionData))
        .unwrap()
        .then((response) => {
          // Handle successful registration, e.g., navigate to dashboard
          navigate("/admin");
        })
        .catch((error) => {
          console.error("Admin Registration failed:", error);
          // Optionally, handle specific error messages here
        });
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "500px",
        margin: "0 auto",
        background: "rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(10px)",
        boxShadow: `0 8px 32px 0 ${theme.palette.blackShades.dark}`,
        borderRadius: "16px",
        border: `1px solid ${theme.palette.blackShades.light}`,
        color: theme.palette.silverShades.light,
        p: { xs: 2, sm: 4 },
      }}
    >
      <Typography variant="h5" align="center" gutterBottom>
        Register as Admin
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTextField label="Username" name="username" variant="outlined" fullWidth required value={formData.username} onChange={(e) => handleChange(e, "username")} error={!!errors.username} helperText={errors.username} />{" "}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField label="Password" name="password" type="password" variant="outlined" fullWidth required value={formData.password} onChange={(e) => handleChange(e, "password")} error={!!errors.password} helperText={errors.password} />{" "}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTextField label="Email" name="email" type="email" variant="outlined" fullWidth required value={formData.email} onChange={(e) => handleChange(e, "email")} error={!!errors.email} helperText={errors.email} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField label="Cell Number" name="cell" type="text" variant="outlined" fullWidth required value={formData.cell} onChange={(e) => handleChange(e, "cell")} error={!!errors.cell} helperText={errors.cell} />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTextField label="ID Number" name="id" type="text" variant="outlined" fullWidth required value={formData.id} onChange={(e) => handleChange(e, "id")} error={!!errors.id} helperText={errors.id} />
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
        </Grid>

        <Grid container spacing={2}>
          {/* Authorization Key */}
          <Grid item xs={12}>
            <CustomTextField label="Authorization Key" name="authorizationKey" type="text" variant="outlined" fullWidth required value={formData.authorizationKey} onChange={(e) => handleChange(e, "authorizationKey")} error={!!errors.authorizationKey} helperText={errors.authorizationKey} />
          </Grid>
        </Grid>

        {/* Submit Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <Button
            variant="contained"
            type="submit"
            disabled={loading}
            sx={{
              backgroundColor: theme.palette.bronzeShades.bronze2,
              color: theme.palette.silverShades.light,
              "&:hover": {
                backgroundColor: theme.palette.bronzeShades.bronze1,
              },
            }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Register"}
          </Button>
        </Box>

        {/* Error Message */}
        {error && (
          <Typography variant="body2" color="error" align="center" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </form>
    </Box>
  );
};

export default RegisterAdmin;
