// CustomNumberField.jsx

import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useScale } from "../../ScaleContext";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

// Create a styled TextField that accepts a 'scale' prop
const StyledNumberField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "scale",
})(({ theme, scale }) => ({
  // Overall margin between text fields
  margin: scale > 1 ? theme.spacing(1.5, 0) : theme.spacing(2, 0),

  // Label styles
  "& label": {
    color: theme.palette.silverShades.light,
    fontSize: scale > 1 ? "0.8rem" : "1rem",
    transform: scale > 1 ? "translate(5px, -18px) scale(1)" : "translate(5px, -20px) scale(1)",
    fontWeight: 200,
  },
  "& input": {
    color: "rgba(255,255,255,1)",
  },
  "& label.Mui-focused": {
    color: theme.palette.silverShades.light,
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "rgba(0,0,0,0.25)",
    padding: 0,
    "& fieldset": {
      borderColor: theme.palette.blackShades.medium,
      padding: "0 8px",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.blackShades.dark,
      backgroundColor: "rgba(0,0,0,0.45)",
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.blackShades.black,
    },
    "& .MuiOutlinedInput-input": {
      padding: scale > 1 ? "4px 8px" : "8px 16px",
      boxSizing: "border-box",
      lineHeight: 1.2,
      fontSize: scale > 1 ? "0.8rem" : "1rem",
      minHeight: scale > 1 ? "24px" : "32px",
    },
  },
  "& .MuiInputAdornment-root": {
    margin: 0,
    "& > *": {
      padding: 0,
    },
  },
  // Autofill styles
  "& input:-webkit-autofill": {
    backgroundColor: "rgba(255, 255, 255, 0.1) !important",
    color: theme.palette.silverShades.light,
    "-webkit-text-fill-color": theme.palette.silverShades.light,
    transition: "background-color 5000s ease-in-out 0s",
  },
  // Custom styles for the adornment icons
  "& .MuiIconButton-root": {
    color: theme.palette.silverShades.light,
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const CustomNumberField = (props) => {
  const scale = useScale();
  const { value, onChange, ...otherProps } = props;

  const handleIncrement = () => {
    if (onChange) {
      onChange({ target: { value: (parseInt(value) || 0) + 1 } });
    }
  };

  const handleDecrement = () => {
    if (onChange) {
      onChange({ target: { value: (parseInt(value) || 0) - 1 } });
    }
  };

  return (
    <StyledNumberField
      type="number"
      scale={scale}
      value={value}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleIncrement} size="small">
              <ArrowDropUpIcon />
            </IconButton>
            <IconButton onClick={handleDecrement} size="small">
              <ArrowDropDownIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...otherProps}
    />
  );
};

export default CustomNumberField;
