// src/components/common/TermsAndConditions.jsx

import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const TermsAndConditions = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: 4,
        maxWidth: 800,
        margin: '0 auto',
        backgroundColor: 'common.bg',
        borderRadius: 2,
        boxShadow: `0 4px 12px rgba(0, 0, 0, 0.1)`,
        color: 'silverShades.light',
      }}
    >
      <Typography variant="h4" gutterBottom align="center">
        Terms and Conditions
      </Typography>
      
      <Typography variant="body1" paragraph>
        Welcome to [Your Company Name]! These terms and conditions outline the rules and regulations for the use of our Sub-Meter Vending services.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        1. Acceptance of Terms
      </Typography>
      <Typography variant="body2" paragraph>
        By accessing this website and using our services, you accept these terms and conditions. Do not continue to use [Your Company Name]'s services if you do not agree to take all of the terms and conditions stated on this page.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        2. Services
      </Typography>
      <Typography variant="body2" paragraph>
        We provide sub-meter vending solutions for residential and commercial properties. Our services include installation, maintenance, and support for sub-metering equipment.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        3. User Responsibilities
      </Typography>
      <Typography variant="body2" paragraph>
        Users are responsible for providing accurate information during registration and for maintaining the confidentiality of their account credentials. Users agree not to misuse our services in any way.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        4. Limitation of Liability
      </Typography>
      <Typography variant="body2" paragraph>
        [Your Company Name] shall not be liable for any damages arising out of or related to the use of our services. We do not guarantee that our services will be uninterrupted or error-free.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        5. Governing Law
      </Typography>
      <Typography variant="body2" paragraph>
        These terms and conditions are governed by and construed in accordance with the laws of [Your Country/State], and you irrevocably submit to the exclusive jurisdiction of the courts in that location.
      </Typography>
      
      <Typography variant="body1" paragraph>
        For any questions about these Terms and Conditions, please contact us at{' '}
        <Link href="mailto:support@yourcompany.com" color="secondary.main">
          customers@meterminds.co.za
        </Link>
        .
      </Typography>
    </Box>
  );
};

export default TermsAndConditions;
