// src/theme/palette.js
const palette = {
  primary: {
    main: "#232526", // Dark shade
  },
  secondary: {
    main: "#966919", // Bronze
  },
  blackShades: {
    black: "#000000",
    dark: "#171616",
    medium: "#232526",
    light: "#483C32",
  },
  bronzeShades: {
    bronze1: "#966919",
    bronze2: "#BC9340",
    bronze3: "#B79E6B",
    bronze4: "#765931",
    bronze5: "#80461B",
    bronze6: "#483330",
  },
  silverShades: {
    light: "#E5E4E2",
    dark: "#C0C0C0",
  },
  common: {
    white: "#feeeee",
    bg: "#272727",
  },
  shadows: {
    light: "#212121", // Neumorphic light shadow color
    dark: "#141414",  // Neumorphic dark shadow color
  },
};

export default palette;
