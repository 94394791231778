// src/pages/login/components/ForgotPassword.jsx
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../../redux/slices/authSlice";
import { toast } from "react-toastify";
import { Box, TextField, Button, CircularProgress, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: theme.palette.bronzeShades.bronze1,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: theme.palette.bronzeShades.bronze4,
  },
  "& .MuiInputBase-input": {
    color: theme.palette.silverShades.light,
  },
  "& .MuiFilledInput-underline:before": {
    borderBottomColor: theme.palette.silverShades.light,
  },
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: theme.palette.silverShades.light,
  },
}));

function ForgotPassword({ onBack }) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [contact, setContact] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);

  const handleChange = (e) => {
    setContact(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await dispatch(forgotPassword({ contact })).unwrap();
      toast.success("Please check your email for password reset instructions.");
      onBack(); // Return to login form
    } catch (err) {
      toast.error(err.message || "Failed to send forgot password request.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mt: 1,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          color: theme.palette.bronzeShades.bronze4,
          fontWeight: "bold",
        }}
      >
        Forgot Password
      </Typography>
      <form onSubmit={handleSubmit}>
        <StyledTextField
          autoFocus
          margin="dense"
          name="contact"
          label="Email or Cell Number"
          type="text"
          fullWidth
          variant="filled"
          value={contact}
          onChange={handleChange}
          required
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            borderRadius: "8px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            mt: 2,
            p: 4,
          }}
        >
          <Button
            onClick={onBack}
            sx={{
              backgroundColor: theme.palette.bronzeShades.bronze3,
              color: theme.palette.silverShades.light,
              "&:hover": {
                backgroundColor: theme.palette.bronzeShades.bronze4,
              },
            }}
            variant="contained"
          >
            Back
          </Button>
          <Button
            type="submit"
            sx={{
              backgroundColor: theme.palette.bronzeShades.bronze2,
              color: theme.palette.silverShades.light,
              "&:hover": {
                backgroundColor: theme.palette.bronzeShades.bronze1,
              },
            }}
            variant="contained"
            disabled={isSubmitting || loading}
          >
            {isSubmitting || loading ? <CircularProgress size={24} color={theme.palette.silverShades.light} /> : "Submit"}
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default ForgotPassword;
